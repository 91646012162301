<template>
  <div>
    <vs-popup fullscreen :buttonCloseHidden="true" title="Catalogue de verre" :active.sync="popupCatalogue">
      <div class="vx-row">
        <div class="vx-col md:w-1/2">
          <vx-card title="Valeurs Corrections" :noShadow="true" :cardBorder="true">
            <div class="vx-row">
              <div class="vx-col md:w-1/2">
                <h4>Oeil Droit</h4>
                <template v-if="parseFloat(CylVD) > 0" >
                  <h5>Sphère: {{SphVD ? SphVD : ''}}, Cylindre: {{CylVD ? CylVD : ''}}, Axe: {{AxeVD ? `${AxeVD}°` : ''}}, Addition: {{AddVD ? AddVD : ''}}</h5>
                  <h5>
                    Sphère : {{Math.sign(parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)) === 1 || Math.sign(parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)) === 0 ? `+${parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)}` : parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)}}
                    , Cylindre: {{-(parseFloat(CylVD))}}, Axe: {{ (parseInt(AxeVD) + 90) > 180 ? parseInt(AxeVD) + 90 - 180 : parseInt(AxeVD) + 90 }}
                  </h5>
                </template>
                <template v-else>
                  <h5 class="w-full">
                    Sphère : {{Math.sign(parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)) === 1 || Math.sign(parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)) === 0 ? `+${parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)}` : parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)}}
                    , Cylindre: {{-(parseFloat(CylVD))}}, Axe: {{ (parseInt(AxeVD) + 90) > 180 ? parseInt(AxeVD) + 90 - 180 : parseInt(AxeVD) + 90 }},
                    Addition: {{AddVD}}
                  </h5>
                  <h5>(Sphère: {{SphVD ? SphVD : ''}}, Cylindre: {{CylVD ? CylVD : ''}}, Axe: {{AxeVD ? `${AxeVD}°` : ''}}°)</h5>
                </template>
              </div>
              <div class="vx-col md:w-1/2">
                <h4>Oeil Gauche</h4>
                <template v-if="parseFloat(CylVG) > 0" >
                  <h5>Sphère: {{SphVG}}, Cylindre: {{CylVG}}, Axe: {{AxeVG}}°, Addition: {{AddVG}}</h5>
                  <h5>
                    Sphère : {{Math.sign(parseFloat((parseFloat(SphVD) + parseFloat(CylVG))).toFixed(2)) === 1 || Math.sign(parseFloat((parseFloat(SphVD) + parseFloat(CylVG))).toFixed(2)) === 0 ? `+${parseFloat((parseFloat(SphVD) + parseFloat(CylVG))).toFixed(2)}` : parseFloat((parseFloat(SphVD) + parseFloat(CylVG))).toFixed(2)}}
                    , Cylindre: {{-(parseFloat(CylVG))}}, Axe: {{ (parseInt(AxeVG) + 90) > 180 ? parseInt(AxeVG) + 90 - 180 : parseInt(AxeVG) + 90 }}
                  </h5>
                </template>
                <template v-else>
                  <h5 class="w-full">
                    Sphère : {{Math.sign(parseFloat((parseFloat(SphVG) + parseFloat(CylVG))).toFixed(2)) === 1 || Math.sign(parseFloat((parseFloat(SphVG) + parseFloat(CylVG))).toFixed(2)) === 0 ? `+${parseFloat((parseFloat(SphVG) + parseFloat(CylVG))).toFixed(2)}` : parseFloat((parseFloat(SphVG) + parseFloat(CylVG))).toFixed(2)}}
                    , Cylindre: {{-(parseFloat(CylVG))}}, Axe: {{ (parseInt(AxeVG) + 90) > 180 ? parseInt(AxeVG) + 90 - 180 : parseInt(AxeVG) + 90 }},
                    Addition: {{AddVG}}
                  </h5>
                  <h5>(Sphère: {{SphVG}}, Cylindre: {{CylVG}}, Axe: {{AxeVG}}°)</h5>
                </template>
              </div>
            </div>
          </vx-card>
          <vx-card class="mt-4" title="Porteur" :noShadow="true" :cardBorder="true" >
            <div class="vx-row">
              <div class="vx-col md:w-1/2">
                <span>Nom: {{client ? client.Nom : ''}}</span>
              </div>
              <div class="vx-col md:w-1/2">
                <span>Téléphone: {{client ? client.Adresse.mobile : ''}} /{{client ?client.Adresse.tel : ''}}</span>
              </div>
              <div class="vx-col md:w-1/2">
                <span>Prénom: {{client ? client.Prenoms : ''}}</span>
              </div>
              <div class="vx-col md:w-1/2">
                <span>Email: {{client ? client.Adresse.email : ''}}</span>
              </div>
            </div>
          </vx-card>
          <vx-card class="mt-4" title="Prix Verres" :noShadow="true" :cardBorder="true" >
            <div class="vx-row">
              <div class="vx-col w-full">
                <span>OEIL Droit:</span> <br>
                <span>Option: {{ moneyFormatter(Prix_OptionVerreOD1) }}</span> <br>
                <span>Traitement: {{ moneyFormatter(Prix_TraitementOD1) }}</span> <br>
                <span>Fabrication/Supplement: {{ moneyFormatter(Prix_FabricationOD1) }}</span> <br>
                <span>Coloration: {{ moneyFormatter(Prix_ColorationOD1) }}</span> <br>
                <span>Montant HT: {{moneyFormatter((+Prix_OptionVerreOD1 + +Prix_TraitementOD1 + +Prix_FabricationOD1 + +Prix_ColorationOD1))}}</span> <br>
                <span>Montant TTC: {{moneyFormatter((+Prix_OptionVerreOD1 + +Prix_TraitementOD1 + +Prix_FabricationOD1 + +Prix_ColorationOD1))}}</span>
                <br><br>
                <span>OEIL Gauche:</span> <br>
                <span>Option: {{ moneyFormatter(Prix_OptionVerreOG1) }}</span> <br>
                <span>Traitement: {{ moneyFormatter(Prix_TraitementOG1) }}</span> <br>
                <span>Fabrication/Supplement: {{ moneyFormatter(Prix_FabricationOG1) }}</span> <br>
                <span>Coloration: {{ moneyFormatter(Prix_ColorationOG1) }}</span> <br>
                <span>Montant HT: {{moneyFormatter((+Prix_OptionVerreOG1 + +Prix_TraitementOG1 + +Prix_FabricationOG1 + +Prix_ColorationOG1))}}</span> <br>
                <span>Montant TTC: {{moneyFormatter((+Prix_OptionVerreOG1 + +Prix_TraitementOG1 + +Prix_FabricationOG1 + +Prix_ColorationOG1))}}</span>
              </div>
            </div>
          </vx-card>
        </div>
        <div class="vx-col md:w-1/2">
          <vx-card title="Verres" :noShadow="true" :cardBorder="true" >
            <div class="vx-row">
              <div class="vx-col md:w-1/3 text-center">
                <vs-button :color="btnODColor" class="w-full" type="filled" @click="btnODColor='primary';btnOGColor='lightgray';showODCommandeVerre=true;showOGCommandeVerre=false">OD</vs-button>
              </div>
              <div class="vx-col md:w-1/3 text-center">
                <vs-button v-if="((IdFourVerrOD1 === IdFourVerrOG1) && (Id_FoyerOD1 === Id_FoyerOG1) && (IdGammeVerrOD1 === IdGammeVerrOG1) && (Id_MatiereOD1 === Id_MatiereOG1) && (Id_IndiceOD1 === Id_IndiceOG1) && (Id_OptionVerreOD1 === Id_OptionVerreOG1) && (Id_TraitementOD1 === Id_TraitementOG1) && (Id_FabricationOD1 === Id_FabricationOG1) && (Id_ColorationOD1 === Id_ColorationOG1) )"  color="primary" class="" type="filled">=</vs-button>
                <vs-button v-else  color="danger" class="" type="filled" @click="IdFourVerrOG1=IdFourVerrOD1;Id_FoyerOG1=Id_FoyerOD1;IdGammeVerrOG1=IdGammeVerrOD1;Id_MatiereOG1=Id_MatiereOD1;Id_IndiceOG1=Id_IndiceOD1;Id_OptionVerreOG1=Id_OptionVerreOD1;Id_TraitementOG1=Id_TraitementOD1;Id_FabricationOG1=Id_FabricationOD1;Id_ColorationOG1=Id_ColorationOD1;RefVerG1=RefVerD1;PrixVerG1=PrixVerD1;Prix_OptionVerreOG1=Prix_OptionVerreOD1;Prix_TraitementOG1=Prix_TraitementOD1;Prix_FabricationOG1=Prix_FabricationOD1;Prix_ColorationOG1=Prix_ColorationOD1" >≠</vs-button>
              </div>
              <div class="vx-col md:w-1/3 text-center">
                <vs-button :color="btnOGColor" class="w-full" type="filled" @click="btnODColor='lightgray';btnOGColor='primary';showODCommandeVerre=false;showOGCommandeVerre=true">OG</vs-button>
              </div>
            </div>
            <template v-if="showODCommandeVerre">
              <div class="vx-row">
                <div class="vx-col md:w-1/2">
                  <vs-select label="Fournisseur" class="w-full mt-4"  autocomplete v-model="IdFourVerrOD1" @change="filterVerreDataOD1()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Fournisseur}`" v-for="(item,index) in fournisseur_verres_data" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Type de verre (Foyer)" class="w-full mt-4"  autocomplete v-model="Id_FoyerOD1" @change="filterVerreDataOD1()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Foyer}`" v-for="(item,index) in typeVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Gamme de verre" class="w-full mt-4"  autocomplete v-model="IdGammeVerrOD1" @change="filterVerreDataOD1()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_GamVer}`" v-for="(item,index) in gammeVerresData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Matière" class="w-full mt-4"  autocomplete v-model="Id_MatiereOD1">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Matiere}`" v-for="(item,index) in matiereVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Indice" class="w-full mt-4"  autocomplete v-model="Id_IndiceOD1" @change="filterVerreDataOD1()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.indice}`" v-for="(item,index) in indiceVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Option" class="w-full mt-4"  autocomplete v-model="Id_OptionVerreOD1" @change="setOptionODPrice1(Id_OptionVerreOD1)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_version}`" v-for="(item,index) in versionVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Traitement" class="w-full mt-4"  autocomplete v-model="Id_TraitementOD1" @change="setTraitementODPrice1(Id_TraitementOD1)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Traitement}`" v-for="(item,index) in traitementsData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Fabrication" class="w-full mt-4"  autocomplete v-model="Id_FabricationOD1" @change="setFabricationODPrice1(Id_FabricationOD1)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Fabrication}`" v-for="(item,index) in fabricationData" />
                  </vs-select>
                </div>
              </div>
              <div class="vx-row">
                <div class="vx-col md:w-1/2">
                  <vs-select label="Coloration" class="w-full mt-4"  autocomplete v-model="Id_ColorationOD1" @change="setColorationODPrice1(Id_ColorationOD1)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Coloration}`" v-for="(item,index) in colorationData" />
                  </vs-select>
                </div>
                <div v-if="(IdFourVerrOD1 || Id_FoyerOD1 || IdGammeVerrOD1 || Id_MatiereOD1 || Id_IndiceOD1 || Id_OptionVerreOD1 || Id_TraitementOD1 || Id_FabricationOD1 || Id_ColorationOD1)" class="vx-col md:w-1/2">
                  <vs-button color="danger" class="w-full mt-10" type="filled" @click="IdFourVerrOD1=undefined;Id_FoyerOD1=undefined;IdGammeVerrOD1=undefined;Id_MatiereOD1=undefined;Id_IndiceOD1=undefined;Id_OptionVerreOD1=undefined;Id_TraitementOD1=undefined;Id_FabricationOD1=undefined;Id_ColorationOD1=undefined">Réinitialiser</vs-button>
                </div>
              </div>
            </template>
            <template v-if="showOGCommandeVerre">
              <div class="vx-row">
                <div class="vx-col md:w-1/2">
                  <vs-select label="Fournisseur" class="w-full mt-4"  autocomplete v-model="IdFourVerrOG1" @change="filterVerreDataOG1()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Fournisseur}`" v-for="(item,index) in fournisseur_verres_data" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Type de verre (Foyer)" class="w-full mt-4"  autocomplete v-model="Id_FoyerOG1" @change="filterVerreDataOG1()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Foyer}`" v-for="(item,index) in typeVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Gamme de verre" class="w-full mt-4"  autocomplete v-model="IdGammeVerrOG1" @change="filterVerreDataOD1()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_GamVer}`" v-for="(item,index) in gammeVerresData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Matière" class="w-full mt-4"  autocomplete v-model="Id_MatiereOG1">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Matiere}`" v-for="(item,index) in matiereVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Indice" class="w-full mt-4"  autocomplete v-model="Id_IndiceOG1" @change="filterVerreDataOG1()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.indice}`" v-for="(item,index) in indiceVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Option" class="w-full mt-4"  autocomplete v-model="Id_OptionVerreOG1" @change="setOptionOGPrice1(Id_OptionVerreOG1)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_version}`" v-for="(item,index) in versionVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Traitement" class="w-full mt-4"  autocomplete v-model="Id_TraitementOG1" @change="setTraitementOGPrice1(Id_TraitementOG1)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Traitement}`" v-for="(item,index) in traitementsData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Fabrication" class="w-full mt-4"  autocomplete v-model="Id_FabricationOG1" @change="setFabricationOGPrice1(Id_FabricationOG1)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Fabrication}`" v-for="(item,index) in fabricationData" />
                  </vs-select>
                </div>
              </div>
              <div class="vx-row">
                <div class="vx-col md:w-1/2">
                  <vs-select label="Coloration" class="w-full mt-4"  autocomplete v-model="Id_ColorationOG1" @change="setColorationOGPrice1(Id_ColorationOG1)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Coloration}`" v-for="(item,index) in colorationData" />
                  </vs-select>
                </div>
                <div v-if="(IdFourVerrOG1 || Id_FoyerOG1 || IdGammeVerrOG1 || Id_MatiereOG1 || Id_IndiceOG1 || Id_OptionVerreOG1 || Id_TraitementOG1 || Id_FabricationOG1 || Id_ColorationOG1)" class="vx-col md:w-1/2">
                  <vs-button color="danger" class="w-full mt-10" type="filled" @click="IdFourVerrOG1=undefined;Id_FoyerOG1=undefined;IdGammeVerrOG1=undefined;Id_MatiereOG1=undefined;Id_IndiceOG1=undefined;Id_OptionVerreOG1=undefined;Id_TraitementOG1=undefined;Id_FabricationOG1=undefined;Id_ColorationOG1=undefined">Réinitialiser</vs-button>
                </div>
              </div>
            </template>
          </vx-card>
          <div class="vx-row flex justify-end mt-5">
            <div class="vx-col">
              <vs-button color="danger" class="w-full" @click="popupCatalogue=false">Annuler</vs-button>
            </div>
            <div class="vx-col">
              <vs-button color="primary" class="w-full" @click="prixVerreOD1();prixVerreOG1();popupCatalogue=false">Valider</vs-button>
            </div>
          </div>

        </div>
      </div>

    </vs-popup>
    <vs-popup fullscreen :buttonCloseHidden="true" title="Catalogue de verre" :active.sync="popupCatalogue2">
      <div class="vx-row">
        <div class="vx-col md:w-1/2">
          <vx-card title="Valeurs Corrections" :noShadow="true" :cardBorder="true">
            <div class="vx-row">
              <div class="vx-col md:w-1/2">
                <h4>Oeil Droit</h4>
                <template v-if="parseFloat(CylVD) > 0" >
                  <h5>Sphère: {{SphVD}}, Cylindre: {{CylVD}}, Axe: {{AxeVD}}°, Addition: {{AddVD}}</h5>
                  <h5>
                    Sphère : {{Math.sign(parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)) === 1 || Math.sign(parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)) === 0 ? `+${parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)}` : parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)}}
                    , Cylindre: {{-(parseFloat(CylVD))}}, Axe: {{ (parseInt(AxeVD) + 90) > 180 ? parseInt(AxeVD) + 90 - 180 : parseInt(AxeVD) + 90 }}
                  </h5>
                </template>
                <template v-else>
                  <h5 class="w-full">
                    Sphère : {{Math.sign(parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)) === 1 || Math.sign(parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)) === 0 ? `+${parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)}` : parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)}}
                    , Cylindre: {{-(parseFloat(CylVD))}}, Axe: {{ (parseInt(AxeVD) + 90) > 180 ? parseInt(AxeVD) + 90 - 180 : parseInt(AxeVD) + 90 }},
                    Addition: {{AddVD}}
                  </h5>
                  <h5>(Sphère: {{SphVD}}, Cylindre: {{CylVD}}, Axe: {{AxeVD}}°)</h5>
                </template>
              </div>
              <div class="vx-col md:w-1/2">
                <h4>Oeil Gauche</h4>
                <template v-if="parseFloat(CylVG) > 0" >
                  <h5>Sphère: {{SphVG}}, Cylindre: {{CylVG}}, Axe: {{AxeVG}}°, Addition: {{AddVG}}</h5>
                  <h5>
                    Sphère : {{Math.sign(parseFloat((parseFloat(SphVD) + parseFloat(CylVG))).toFixed(2)) === 1 || Math.sign(parseFloat((parseFloat(SphVD) + parseFloat(CylVG))).toFixed(2)) === 0 ? `+${parseFloat((parseFloat(SphVD) + parseFloat(CylVG))).toFixed(2)}` : parseFloat((parseFloat(SphVD) + parseFloat(CylVG))).toFixed(2)}}
                    , Cylindre: {{-(parseFloat(CylVG))}}, Axe: {{ (parseInt(AxeVG) + 90) > 180 ? parseInt(AxeVG) + 90 - 180 : parseInt(AxeVG) + 90 }}
                  </h5>
                </template>
                <template v-else>
                  <h5 class="w-full">
                    Sphère : {{Math.sign(parseFloat((parseFloat(SphVG) + parseFloat(CylVG))).toFixed(2)) === 1 || Math.sign(parseFloat((parseFloat(SphVG) + parseFloat(CylVG))).toFixed(2)) === 0 ? `+${parseFloat((parseFloat(SphVG) + parseFloat(CylVG))).toFixed(2)}` : parseFloat((parseFloat(SphVG) + parseFloat(CylVG))).toFixed(2)}}
                    , Cylindre: {{-(parseFloat(CylVG))}}, Axe: {{ (parseInt(AxeVG) + 90) > 180 ? parseInt(AxeVG) + 90 - 180 : parseInt(AxeVG) + 90 }},
                    Addition: {{AddVG}}
                  </h5>
                  <h5>(Sphère: {{SphVG}}, Cylindre: {{CylVG}}, Axe: {{AxeVG}}°)</h5>
                </template>
              </div>
            </div>
          </vx-card>
          <vx-card class="mt-4" title="Porteur" :noShadow="true" :cardBorder="true" >
            <div class="vx-row">
              <div class="vx-col md:w-1/2">
                <span>Nom: {{client ? client.Nom : ''}}</span>
              </div>
              <div class="vx-col md:w-1/2">
                <span>Téléphone: {{client ? client.Adresse.mobile : ''}} /{{client ?client.Adresse.tel : ''}}</span>
              </div>
              <div class="vx-col md:w-1/2">
                <span>Prénom: {{client ? client.Prenoms : ''}}</span>
              </div>
              <div class="vx-col md:w-1/2">
                <span>Email: {{client ? client.Adresse.email : ''}}</span>
              </div>
            </div>
          </vx-card>
          <vx-card class="mt-4" title="Prix Verres" :noShadow="true" :cardBorder="true" >
            <div class="vx-row">
              <div class="vx-col w-full">
                <span>OEIL Droit:</span> <br>
                <span>Option: {{ moneyFormatter(Prix_OptionVerreOD2) }}</span> <br>
                <span>Traitement: {{ moneyFormatter(Prix_TraitementOD2) }}</span> <br>
                <span>Fabrication/Supplement: {{ moneyFormatter(Prix_FabricationOD2) }}</span> <br>
                <span>Coloration: {{ moneyFormatter(Prix_ColorationOD2) }}</span> <br>
                <span>Montant HT: {{moneyFormatter((+Prix_OptionVerreOD2 + +Prix_TraitementOD2 + +Prix_FabricationOD2 + +Prix_ColorationOD2))}}</span> <br>
                <span>Montant TTC: {{moneyFormatter((+Prix_OptionVerreOD2 + +Prix_TraitementOD2 + +Prix_FabricationOD2 + +Prix_ColorationOD2))}}</span>
                <br><br>
                <span>OEIL Gauche:</span> <br>
                <span>Option: {{ moneyFormatter(Prix_OptionVerreOG2) }}</span> <br>
                <span>Traitement: {{ moneyFormatter(Prix_TraitementOG2) }}</span> <br>
                <span>Fabrication/Supplement: {{ moneyFormatter(Prix_FabricationOG2) }}</span> <br>
                <span>Coloration: {{ moneyFormatter(Prix_ColorationOG2) }}</span> <br>
                <span>Montant HT: {{moneyFormatter((+Prix_OptionVerreOG2 + +Prix_TraitementOG2 + +Prix_FabricationOG2 + +Prix_ColorationOG2))}}</span> <br>
                <span>Montant TTC: {{moneyFormatter((+Prix_OptionVerreOG2 + +Prix_TraitementOG2+ +Prix_FabricationOG2+ +Prix_ColorationOG2))}}</span>
              </div>
            </div>
          </vx-card>
        </div>
        <div class="vx-col md:w-1/2">
          <vx-card title="Verres" :noShadow="true" :cardBorder="true" >
            <div class="vx-row">
              <div class="vx-col md:w-1/3 text-center">
                <vs-button :color="btnODColor" class="w-full" type="filled" @click="btnODColor='primary';btnOGColor='lightgray';showODCommandeVerre2=true;showOGCommandeVerre2=false">OD</vs-button>
              </div>
              <div class="vx-col md:w-1/3 text-center">
                <vs-button v-if="((IdFourVerrOD2 === IdFourVerrOG2) && (Id_FoyerOD2 === Id_FoyerOG2) && (IdGammeVerrOD2 === IdGammeVerrOG2) && (Id_MatiereOD2 === Id_MatiereOG2) && (Id_IndiceOD2 === Id_IndiceOG2) && (Id_OptionVerreOD2 === Id_OptionVerreOG2) && (Id_TraitementOD2 === Id_TraitementOG2) && (Id_FabricationOD2 === Id_FabricationOG2) && (Id_ColorationOD2 === Id_ColorationOG2) )"  color="primary" class="" type="filled">=</vs-button>
                <vs-button v-else  color="danger" class="" type="filled" @click="IdFourVerrOG2=IdFourVerrOD2;Id_FoyerOG2=Id_FoyerOD2;IdGammeVerrOG2=IdGammeVerrOD2;Id_MatiereOG2=Id_MatiereOD2;Id_IndiceOG2=Id_IndiceOD2;Id_OptionVerreOG2=Id_OptionVerreOD2;Id_TraitementOG2=Id_TraitementOD2;Id_FabricationOG2=Id_FabricationOD2;Id_ColorationOG2=Id_ColorationOD2;RefVerG2=RefVerD2;PrixVerG2=PrixVerD2;Prix_OptionVerreOG2=Prix_OptionVerreOD2;Prix_TraitementOG2=Prix_TraitementOD2;Prix_FabricationOG2=Prix_FabricationOD2;Prix_ColorationOG2=Prix_ColorationOD2" >≠</vs-button>
              </div>
              <div class="vx-col md:w-1/3 text-center">
                <vs-button :color="btnOGColor" class="w-full" type="filled" @click="btnODColor='lightgray';btnOGColor='primary';showODCommandeVerre2=false;showOGCommandeVerre2=true">OG</vs-button>
              </div>
            </div>
            <template v-if="showODCommandeVerre2">
              <div class="vx-row">
                <div class="vx-col md:w-1/2">
                  <vs-select label="Fournisseur" class="w-full mt-4"  autocomplete v-model="IdFourVerrOD2" @change="filterVerreDataOD2()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Fournisseur}`" v-for="(item,index) in fournisseur_verres_data" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Type de verre (Foyer)" class="w-full mt-4"  autocomplete v-model="Id_FoyerOD2" @change="filterVerreDataOD2()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Foyer}`" v-for="(item,index) in typeVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Gamme de verre" class="w-full mt-4"  autocomplete v-model="IdGammeVerrOD2" @change="filterVerreDataOD2()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_GamVer}`" v-for="(item,index) in gammeVerresData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Matière" class="w-full mt-4"  autocomplete v-model="Id_MatiereOD2">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Matiere}`" v-for="(item,index) in matiereVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Indice" class="w-full mt-4"  autocomplete v-model="Id_IndiceOD2" @change="filterVerreDataOD2()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.indice}`" v-for="(item,index) in indiceVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Option" class="w-full mt-4"  autocomplete v-model="Id_OptionVerreOD2" @change="setOptionODPrice2(Id_OptionVerreOD2)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_version}`" v-for="(item,index) in versionVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Traitement" class="w-full mt-4"  autocomplete v-model="Id_TraitementOD2" @change="setTraitementODPrice2(Id_TraitementOD2)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Traitement}`" v-for="(item,index) in traitementsData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Fabrication" class="w-full mt-4"  autocomplete v-model="Id_FabricationOD2" @change="setFabricationODPrice2(Id_FabricationOD2)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Fabrication}`" v-for="(item,index) in fabricationData" />
                  </vs-select>
                </div>
              </div>
              <div class="vx-row">
                <div class="vx-col md:w-1/2">
                  <vs-select label="Coloration" class="w-full mt-4"  autocomplete v-model="Id_ColorationOD2" @change="setColorationODPrice2(Id_ColorationOD2)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Coloration}`" v-for="(item,index) in colorationData" />
                  </vs-select>
                </div>
                <div v-if="(IdFourVerrOD2 || Id_FoyerOD2 || IdGammeVerrOD2 || Id_MatiereOD2 || Id_IndiceOD2 || Id_OptionVerreOD2 || Id_TraitementOD2 || Id_FabricationOD2 || Id_ColorationOD2)" class="vx-col md:w-1/2">
                  <vs-button color="danger" class="w-full mt-10" type="filled" @click="IdFourVerrOD2=undefined;Id_FoyerOD2=undefined;IdGammeVerrOD2=undefined;Id_MatiereOD2=undefined;Id_IndiceOD2=undefined;Id_OptionVerreOD2=undefined;Id_TraitementOD2=undefined;Id_FabricationOD2=undefined;Id_ColorationOD2=undefined">Réinitialiser</vs-button>
                </div>
              </div>
            </template>
            <template v-if="showOGCommandeVerre2">
              <div class="vx-row">
                <div class="vx-col md:w-1/2">
                  <vs-select label="Fournisseur" class="w-full mt-4"  autocomplete v-model="IdFourVerrOG2" @change="filterVerreDataOG2()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Fournisseur}`" v-for="(item,index) in fournisseur_verres_data" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Type de verre (Foyer)" class="w-full mt-4"  autocomplete v-model="Id_FoyerOG2" @change="filterVerreDataOG2()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Foyer}`" v-for="(item,index) in typeVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Gamme de verre" class="w-full mt-4"  autocomplete v-model="IdGammeVerrOG2" @change="filterVerreDataOD2()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_GamVer}`" v-for="(item,index) in gammeVerresData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Matière" class="w-full mt-4"  autocomplete v-model="Id_MatiereOG2">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Matiere}`" v-for="(item,index) in matiereVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Indice" class="w-full mt-4"  autocomplete v-model="Id_IndiceOG2" @change="filterVerreDataOG2()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.indice}`" v-for="(item,index) in indiceVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Option" class="w-full mt-4"  autocomplete v-model="Id_OptionVerreOG2" @change="setOptionOGPrice2(Id_OptionVerreOG2)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_version}`" v-for="(item,index) in versionVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Traitement" class="w-full mt-4"  autocomplete v-model="Id_TraitementOG2" @change="setTraitementOGPrice2(Id_TraitementOG2)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Traitement}`" v-for="(item,index) in traitementsData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Fabrication" class="w-full mt-4"  autocomplete v-model="Id_FabricationOG2" @change="setFabricationOGPrice2(Id_FabricationOG2)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Fabrication}`" v-for="(item,index) in fabricationData" />
                  </vs-select>
                </div>
              </div>
              <div class="vx-row">
                <div class="vx-col md:w-1/2">
                  <vs-select label="Coloration" class="w-full mt-4"  autocomplete v-model="Id_ColorationOG2" @change="setColorationOGPrice2(Id_ColorationOG2)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Coloration}`" v-for="(item,index) in colorationData" />
                  </vs-select>
                </div>
                <div v-if="(IdFourVerrOG2 || Id_FoyerOG2 || IdGammeVerrOG2 || Id_MatiereOG2 || Id_IndiceOG2 || Id_OptionVerreOG2 || Id_TraitementOG2 || Id_FabricationOG2 || Id_ColorationOG2)" class="vx-col md:w-1/2">
                  <vs-button color="danger" class="w-full mt-10" type="filled" @click="IdFourVerrOG2=undefined;Id_FoyerOG2=undefined;IdGammeVerrOG2=undefined;Id_MatiereOG2=undefined;Id_IndiceOG2=undefined;Id_OptionVerreOG2=undefined;Id_TraitementOG2=undefined;Id_FabricationOG2=undefined;Id_ColorationOG2=undefined">Réinitialiser</vs-button>
                </div>
              </div>
            </template>
          </vx-card>
          <div class="vx-row flex justify-end mt-5">
            <div class="vx-col">
              <vs-button color="danger" class="w-full" @click="popupCatalogue2=false">Annuler</vs-button>
            </div>
            <div class="vx-col">
              <vs-button color="primary" class="w-full" @click="prixVerreOD2();prixVerreOG2();popupCatalogue2=false">Valider</vs-button>
            </div>
          </div>
        </div>
      </div>

    </vs-popup>
    <vs-popup fullscreen :buttonCloseHidden="true" title="Catalogue de verre" :active.sync="popupCatalogue3">
      <div class="vx-row">
        <div class="vx-col md:w-1/2">
          <vx-card title="Valeurs Corrections" :noShadow="true" :cardBorder="true">
            <div class="vx-row">
              <div class="vx-col md:w-1/2">
                <h4>Oeil Droit</h4>
                <template v-if="parseFloat(CylVD) > 0" >
                  <h5>Sphère: {{SphVD}}, Cylindre: {{CylVD}}, Axe: {{AxeVD}}°, Addition: {{AddVD}}</h5>
                  <h5>
                    Sphère : {{Math.sign(parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)) === 1 || Math.sign(parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)) === 0 ? `+${parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)}` : parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)}}
                    , Cylindre: {{-(parseFloat(CylVD))}}, Axe: {{ (parseInt(AxeVD) + 90) > 180 ? parseInt(AxeVD) + 90 - 180 : parseInt(AxeVD) + 90 }}
                  </h5>
                </template>
                <template v-else>
                  <h5 class="w-full">
                    Sphère : {{Math.sign(parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)) === 1 || Math.sign(parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)) === 0 ? `+${parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)}` : parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)}}
                    , Cylindre: {{-(parseFloat(CylVD))}}, Axe: {{ (parseInt(AxeVD) + 90) > 180 ? parseInt(AxeVD) + 90 - 180 : parseInt(AxeVD) + 90 }},
                    Addition: {{AddVD}}
                  </h5>
                  <h5>(Sphère: {{SphVD}}, Cylindre: {{CylVD}}, Axe: {{AxeVD}}°)</h5>
                </template>
              </div>
              <div class="vx-col md:w-1/2">
                <h4>Oeil Gauche</h4>
                <template v-if="parseFloat(CylVG) > 0" >
                  <h5>Sphère: {{SphVG}}, Cylindre: {{CylVG}}, Axe: {{AxeVG}}°, Addition: {{AddVG}}</h5>
                  <h5>
                    Sphère : {{Math.sign(parseFloat((parseFloat(SphVD) + parseFloat(CylVG))).toFixed(2)) === 1 || Math.sign(parseFloat((parseFloat(SphVD) + parseFloat(CylVG))).toFixed(2)) === 0 ? `+${parseFloat((parseFloat(SphVD) + parseFloat(CylVG))).toFixed(2)}` : parseFloat((parseFloat(SphVD) + parseFloat(CylVG))).toFixed(2)}}
                    , Cylindre: {{-(parseFloat(CylVG))}}, Axe: {{ (parseInt(AxeVG) + 90) > 180 ? parseInt(AxeVG) + 90 - 180 : parseInt(AxeVG) + 90 }}
                  </h5>
                </template>
                <template v-else>
                  <h5 class="w-full">
                    Sphère : {{Math.sign(parseFloat((parseFloat(SphVG) + parseFloat(CylVG))).toFixed(2)) === 1 || Math.sign(parseFloat((parseFloat(SphVG) + parseFloat(CylVG))).toFixed(2)) === 0 ? `+${parseFloat((parseFloat(SphVG) + parseFloat(CylVG))).toFixed(2)}` : parseFloat((parseFloat(SphVG) + parseFloat(CylVG))).toFixed(2)}}
                    , Cylindre: {{-(parseFloat(CylVG))}}, Axe: {{ (parseInt(AxeVG) + 90) > 180 ? parseInt(AxeVG) + 90 - 180 : parseInt(AxeVG) + 90 }},
                    Addition: {{AddVG}}
                  </h5>
                  <h5>(Sphère: {{SphVG}}, Cylindre: {{CylVG}}, Axe: {{AxeVG}}°)</h5>
                </template>
              </div>
            </div>
          </vx-card>
          <vx-card class="mt-4" title="Porteur" :noShadow="true" :cardBorder="true" >
            <div class="vx-row">
              <div class="vx-col md:w-1/2">
                <span>Nom: {{client ? client.Nom : ''}}</span>
              </div>
              <div class="vx-col md:w-1/2">
                <span>Téléphone: {{client ? client.Adresse.mobile : ''}} /{{client ?client.Adresse.tel : ''}}</span>
              </div>
              <div class="vx-col md:w-1/2">
                <span>Prénom: {{client ? client.Prenoms : ''}}</span>
              </div>
              <div class="vx-col md:w-1/2">
                <span>Email: {{client ? client.Adresse.email : ''}}</span>
              </div>
            </div>
          </vx-card>
          <vx-card class="mt-4" title="Prix Verres" :noShadow="true" :cardBorder="true" >
            <div class="vx-row">
              <div class="vx-col w-full">
                <span>OEIL Droit:</span> <br>
                <span>Option: {{ moneyFormatter(Prix_OptionVerreOD3) }}</span> <br>
                <span>Traitement: {{ moneyFormatter(Prix_TraitementOD3) }}</span> <br>
                <span>Fabrication/Supplement: {{ moneyFormatter(Prix_FabricationOD3) }}</span> <br>
                <span>Coloration: {{ moneyFormatter(Prix_ColorationOD3) }}</span> <br>
                <span>Montant HT: {{moneyFormatter((+Prix_OptionVerreOD3 + +Prix_TraitementOD3 + +Prix_FabricationOD3 + +Prix_ColorationOD3))}}</span> <br>
                <span>Montant TTC: {{moneyFormatter((+Prix_OptionVerreOD3 + +Prix_TraitementOD3 + +Prix_FabricationOD3 + +Prix_ColorationOD3))}}</span>
                <br><br>
                <span>OEIL Gauche:</span> <br>
                <span>Option: {{ moneyFormatter(Prix_OptionVerreOG3) }}</span> <br>
                <span>Traitement: {{ moneyFormatter(Prix_TraitementOG3) }}</span> <br>
                <span>Fabrication/Supplement: {{ moneyFormatter(Prix_FabricationOG3) }}</span> <br>
                <span>Coloration: {{ moneyFormatter(Prix_ColorationOG3) }}</span> <br>
                <span>Montant HT: {{moneyFormatter((+Prix_OptionVerreOG3 + +Prix_TraitementOG3 + +Prix_FabricationOG3 + +Prix_ColorationOG3))}}</span> <br>
                <span>Montant TTC: {{moneyFormatter((+Prix_OptionVerreOG3 + +Prix_TraitementOG3 + +Prix_FabricationOG3+ +Prix_ColorationOG3))}}</span>
              </div>
            </div>
          </vx-card>
        </div>
        <div class="vx-col md:w-1/2">
          <vx-card title="Verres" :noShadow="true" :cardBorder="true" >
            <div class="vx-row">
              <div class="vx-col md:w-1/3 text-center">
                <vs-button :color="btnODColor" class="w-full" type="filled" @click="btnODColor='primary';btnOGColor='lightgray';showODCommandeVerre3=true;showOGCommandeVerre3=false">OD</vs-button>
              </div>
              <div class="vx-col md:w-1/3 text-center">
                <vs-button v-if="((IdFourVerrOD3 === IdFourVerrOG3) && (Id_FoyerOD3 === Id_FoyerOG3) && (IdGammeVerrOD3 === IdGammeVerrOG3) && (Id_MatiereOD3 === Id_MatiereOG3) && (Id_IndiceOD3 === Id_IndiceOG3) && (Id_OptionVerreOD3 === Id_OptionVerreOG3) && (Id_TraitementOD3 === Id_TraitementOG3) && (Id_FabricationOD3 === Id_FabricationOG3) && (Id_ColorationOD3 === Id_ColorationOG3) )"  color="primary" class="" type="filled">=</vs-button>
                <vs-button v-else  color="danger" class="" type="filled" @click="IdFourVerrOG3=IdFourVerrOD3;Id_FoyerOG3=Id_FoyerOD3;IdGammeVerrOG3=IdGammeVerrOD3;Id_MatiereOG3=Id_MatiereOD3;Id_IndiceOG3=Id_IndiceOD3;Id_OptionVerreOG3=Id_OptionVerreOD3;Id_TraitementOG3=Id_TraitementOD3;Id_FabricationOG3=Id_FabricationOD3;Id_ColorationOG3=Id_ColorationOD3;RefVerG3=RefVerD3;PrixVerG3=PrixVerD3;Prix_OptionVerreOG3=Prix_OptionVerreOD3;Prix_TraitementOG3=Prix_TraitementOD3;Prix_FabricationOG3=Prix_FabricationOD3;Prix_ColorationOG3=Prix_ColorationOD3" >≠</vs-button>
              </div>
              <div class="vx-col md:w-1/3 text-center">
                <vs-button :color="btnOGColor" class="w-full" type="filled" @click="btnODColor='lightgray';btnOGColor='primary';showODCommandeVerre3=false;showOGCommandeVerre3=true">OG</vs-button>
              </div>
            </div>
            <template v-if="showODCommandeVerre3">
              <div class="vx-row">
                <div class="vx-col md:w-1/2">
                  <vs-select label="Fournisseur" class="w-full mt-4"  autocomplete v-model="IdFourVerrOD3" @change="filterVerreDataOD3()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Fournisseur}`" v-for="(item,index) in fournisseur_verres_data" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Type de verre (Foyer)" class="w-full mt-4"  autocomplete v-model="Id_FoyerOD3" @change="filterVerreDataOD3()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Foyer}`" v-for="(item,index) in typeVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Gamme de verre" class="w-full mt-4"  autocomplete v-model="IdGammeVerrOD3" @change="filterVerreDataOD3()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_GamVer}`" v-for="(item,index) in gammeVerresData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Matière" class="w-full mt-4"  autocomplete v-model="Id_MatiereOD3">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Matiere}`" v-for="(item,index) in matiereVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Indice" class="w-full mt-4"  autocomplete v-model="Id_IndiceOD3" @change="filterVerreDataOD3()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.indice}`" v-for="(item,index) in indiceVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Option" class="w-full mt-4"  autocomplete v-model="Id_OptionVerreOD3" @change="setOptionODPrice3(Id_OptionVerreOD3)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_version}`" v-for="(item,index) in versionVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Traitement" class="w-full mt-4"  autocomplete v-model="Id_TraitementOD3" @change="setTraitementODPrice3(Id_TraitementOD3)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Traitement}`" v-for="(item,index) in traitementsData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Fabrication" class="w-full mt-4"  autocomplete v-model="Id_FabricationOD3" @change="setFabricationODPrice3(Id_FabricationOD3)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Fabrication}`" v-for="(item,index) in fabricationData" />
                  </vs-select>
                </div>
              </div>
              <div class="vx-row">
                <div class="vx-col md:w-1/2">
                  <vs-select label="Coloration" class="w-full mt-4"  autocomplete v-model="Id_ColorationOD3" @change="setColorationODPrice3(Id_ColorationOD3)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Coloration}`" v-for="(item,index) in colorationData" />
                  </vs-select>
                </div>
                <div v-if="(IdFourVerrOD3 || Id_FoyerOD3 || IdGammeVerrOD3 || Id_MatiereOD3 || Id_IndiceOD3 || Id_OptionVerreOD3 || Id_TraitementOD3 || Id_FabricationOD3 || Id_ColorationOD3)" class="vx-col md:w-1/2">
                  <vs-button color="danger" class="w-full mt-10" type="filled" @click="IdFourVerrOD3=undefined;Id_FoyerOD3=undefined;IdGammeVerrOD3=undefined;Id_MatiereOD3=undefined;Id_IndiceOD3=undefined;Id_OptionVerreOD3=undefined;Id_TraitementOD3=undefined;Id_FabricationOD3=undefined;Id_ColorationOD3=undefined">Réinitialiser</vs-button>
                </div>
              </div>
            </template>
            <template v-if="showOGCommandeVerre3">
              <div class="vx-row">
                <div class="vx-col md:w-1/2">
                  <vs-select label="Fournisseur" class="w-full mt-4"  autocomplete v-model="IdFourVerrOG3" @change="filterVerreDataOG3()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Fournisseur}`" v-for="(item,index) in fournisseur_verres_data" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Type de verre (Foyer)" class="w-full mt-4"  autocomplete v-model="Id_FoyerOG3" @change="filterVerreDataOG3()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Foyer}`" v-for="(item,index) in typeVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Gamme de verre" class="w-full mt-4"  autocomplete v-model="IdGammeVerrOG3" @change="filterVerreDataOD3()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_GamVer}`" v-for="(item,index) in gammeVerresData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Matière" class="w-full mt-4"  autocomplete v-model="Id_MatiereOG3">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Matiere}`" v-for="(item,index) in matiereVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Indice" class="w-full mt-4"  autocomplete v-model="Id_IndiceOG3" @change="filterVerreDataOG3()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.indice}`" v-for="(item,index) in indiceVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Option" class="w-full mt-4"  autocomplete v-model="Id_OptionVerreOG3" @change="setOptionOGPrice3(Id_OptionVerreOG3)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_version}`" v-for="(item,index) in versionVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Traitement" class="w-full mt-4"  autocomplete v-model="Id_TraitementOG3" @change="setTraitementOGPrice3(Id_TraitementOG3)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Traitement}`" v-for="(item,index) in traitementsData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Fabrication" class="w-full mt-4"  autocomplete v-model="Id_FabricationOG3" @change="setFabricationOGPrice3(Id_FabricationOG3)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Fabrication}`" v-for="(item,index) in fabricationData" />
                  </vs-select>
                </div>
              </div>
              <div class="vx-row">
                <div class="vx-col md:w-1/2">
                  <vs-select label="Coloration" class="w-full mt-4"  autocomplete v-model="Id_ColorationOG3" @change="setColorationOGPrice3(Id_ColorationOG3)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Coloration}`" v-for="(item,index) in colorationData" />
                  </vs-select>
                </div>
                <div v-if="(IdFourVerrOG3 || Id_FoyerOG3 || IdGammeVerrOG3 || Id_MatiereOG3 || Id_IndiceOG3 || Id_OptionVerreOG3 || Id_TraitementOG3 || Id_FabricationOG3 || Id_ColorationOG3)" class="vx-col md:w-1/2">
                  <vs-button color="danger" class="w-full mt-10" type="filled" @click="IdFourVerrOG3=undefined;Id_FoyerOG3=undefined;IdGammeVerrOG3=undefined;Id_MatiereOG3=undefined;Id_IndiceOG3=undefined;Id_OptionVerreOG3=undefined;Id_TraitementOG3=undefined;Id_FabricationOG3=undefined;Id_ColorationOG3=undefined">Réinitialiser</vs-button>
                </div>
              </div>
            </template>
          </vx-card>
          <div class="vx-row flex justify-end mt-5">
            <div class="vx-col">
              <vs-button color="danger" class="w-full" @click="popupCatalogue3=false">Annuler</vs-button>
            </div>
            <div class="vx-col">
              <vs-button color="primary" class="w-full" @click="prixVerreOD3();prixVerreOG3();popupCatalogue3=false">Valider</vs-button>
            </div>
          </div>

        </div>
      </div>

    </vs-popup>

    <vs-popup fullscreen title="Prescripteur" :active.sync="popupActivePescripteur">
      <div class="vx-row">
        <div class="vx-col md:w-1/2">
          <vx-card :noShadow="true" :cardBorder="true" title="Centre Hospitalier">
            <div class="vx-row">
              <div class="vx-col md:w-1/2">
                <vs-input label="Raison Sociale" :value="RaisonSoc" @change.native="RaisonSoc=$event.target.value" class="w-full mt-5" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input label="Adresse" :value="AdrCli" @change.native="AdrCli=$event.target.value" class="w-full mt-5" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input type="email" :success="MailCli? true : false" success-text="Mail valide" label="Email" :value="MailCli" @change.native="MailCli=$event.target.value" class="w-full mt-5" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input label="Tel 1" :value="TelCli1" @change.native="TelCli1=$event.target.value" class="w-full mt-5" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input label="Tel 2" :value="TelCli2" @change.native="TelCli2=$event.target.value" class="w-full mt-5" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input label="Tel 3" :value="TelCli3" @change.native="TelCli3=$event.target.value" class="w-full mt-5" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input label="Tel Mobile 1" :value="MobCli1" @change.native="MobCli1=$event.target.value" class="w-full mt-5" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input label="Tel Mobile 2" :value="MobCli2" @change.native="MobCli2=$event.target.value" class="w-full mt-5" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input label="Tel Fax" :value="FaxCli" @change.native="FaxCli=$event.target.value" class="w-full mt-5" />
              </div>
            </div>
            <div class="vx-col mt-5">
              <vs-button color="success" class="w-full" @click="SaveCentreHospitalier">ENREGISTRER</vs-button>
            </div>
          </vx-card>

        </div>
        <div class="vx-col md:w-1/2">
          <vx-card :noShadow="true" :cardBorder="true" title="Prescripteur">
            <div class="vx-row">
              <div class="vx-col md:w-1/2 w-full mt-5">
                  <vs-input label="Nom" :value="NomPresc" @change.native="NomPresc=$event.target.value" class="w-full" />
              </div>
              <div class="vx-col md:w-1/2 w-full mt-5">
                  <vs-input label="Prenoms" v-model="PrenomPresc" class="w-full" />
              </div>
              <div class="vx-col md:w-1/2 w-full mt-5">
                <vs-select autocomplete label="centre hospitalier" class="w-full" v-model="IdCenterHospi" >
                  <vs-select-item :key="index" :value="item._id" :text="item.RaisonSoc" v-for=" (item, index ) in centreHospitaliers" />
                </vs-select>
              </div>

              <div class="vx-col md:w-1/2 w-full md:mt-8">
                <div class="demo-alignment">
                  <span>Ristourne:</span>
                  <div class="flex">
                    <vs-checkbox v-model="Ristourne"></vs-checkbox>
                    <vs-input-number class="ml-8" v-model="taux" label="taux:"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col md:w-1/2 w-full mt-5">
                  <vs-input label="Adresse" v-model="AdrPresc" class="w-full" />
              </div>
              <div class="vx-col md:w-1/2 w-full mt-5">
                  <vs-input type="email" :success="EmailPresc ? true : false" success-text="Mail valide" label="Email" v-model="EmailPresc" class="w-full" />
              </div>
              <div class="vx-col md:w-1/2 w-full mt-5">
                  <vs-input label="Tel" v-model="TelPresc" class="w-full" />
              </div>
              <div class="vx-col md:w-1/2 w-full mt-5">
                  <vs-input label="Mobile" v-model="Mobile" class="w-full" />
              </div>
              <div class="vx-col md:w-1/2 w-full mt-5">
                  <vs-input label="Fax" v-model="FaxPresc" class="w-full" />
              </div>
              <div class="vx-col md:w-1/2 w-full mt-5">
                  <vs-input label="RemarqueP" v-model="RemarqueP" class="w-full" />
              </div>
            </div>
            <div class="vx-row mt-5">
              <div class="vx-col w-full">
                <vs-button color="success" class="w-full" @click="SavePrescripteur" >Enregister</vs-button>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vs-popup>
    <vs-popup fullscreen title="Prise en charge" :active.sync="popupActivePriseEncharge">
      <div class="vx-row">
        <div class="vx-col md:w-1/2">
          <div class="vx-row">
            <div class="vx-col w-full ">
              <vx-card :noShadow="true" :cardBorder="true" title="Nouvelle société">
                <div class="vx-row">
                  <div class="vx-col w-full mt-5">
                    <span style="font-weight: bold;">NB:</span> <span style="font-style: italic;"> La section "Nouvelle société" vous permet d'ajouter une nouvelle société si elle n'existe pas dans la liste des société dans la section "Nouvelle Prise en charge"</span>
                  </div>
                  <div class="vx-col md:w-1/2">
                    <vs-input class="w-full mt-5" type="text" label="Nom Société" :value="NomSociete" @change.native="NomSociete=$event.target.value" name="NomSociete" />
                  </div>
                  <div class="vx-col md:w-1/2">
                    <vs-input class="w-full mt-5" type="text" label="Régistre de commerce" name="RegistreCom" :value="RegistreCom" @change.native="RegistreCom=$event.target.value" />
                  </div>
                </div>

                <div class="vx-row">
                  <div class="vx-col w-full">
                    <div class="mt-8 flex flex-wrap items-center justify-end">
                      <vs-button class="ml-auto mt-2" @click="creatSociete">Enregistrer</vs-button>
                      <vs-button class="ml-4 mt-2" type="border" color="warning" @click="reset_data_societe">Annuler</vs-button>
                    </div>
                  </div>
                </div>
              </vx-card>
            </div>
          </div>
        </div>
        <div class="vx-col md:w-1/2">
          <vx-card :noShadow="true" :cardBorder="true" title="Nouvelle Prise en charge">
            <div class="vx-row">
              <div class="vx-col w-full">
                <vs-input class="w-full mt-4" type="text" label="N°Bon / N°BPC / N°CPC /N°Letter de caution"  name="numBon" :value="numBon" @change.native="numBon=$event.target.value" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-select label="Client" autocomplete class="selectExample w-full select-large mt-4" v-model="IdClient">
                  <vs-select-item :key="index" :value="item._id" :text="`${item.Nom} ${item.Prenoms}`" v-for="(item,index) in clientsData" />
                </vs-select>

              </div>
              <div class="vx-col md:w-1/2">
                <vs-select label="Assurance" autocomplete class="selectExample w-full select-large mt-4" v-model="IdAssurance">
                  <vs-select-item :key="index" :value="item._id" :text="item.Nom_Complet" v-for="(item,index) in assuranceData" />
                </vs-select>
              </div>
              <div class="vx-col md:w-1/2">
                <vs-select label="Société" autocomplete class="selectExample w-full select-large mt-4" v-model="IdSociete">
                  <vs-select-item :key="index" :value="item._id" :text="item.NomSociete" v-for="(item,index) in Societes" />
                </vs-select>
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" type="text" label="Assuré (e) principal(e)"  name="Assurer" :value="Assurer" @change.native="Assurer=$event.target.value" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" type="text" label="Matricule assuré (e) principal(e)"  name="NumSalarie" :value="NumSalarie" @change.native="NumSalarie=$event.target.value" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" type="text" label="Bénéficiaire"  name="Patient" :value="Patient" @change.native="Patient=$event.target.value" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" type="text" label="Matricule bénéficiaire"  name="NumPatient" :value="NumPatient" @change.native="NumPatient=$event.target.value" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" type="text" label="N° Police"  name="NumPolice" :value="NumPolice" @change.native="NumPolice=$event.target.value" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" type="number" label="Ticket Monture"  name="TickMont" :value="TickMont" @change.native="TickMont=$event.target.value" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" type="number" label="Plafon monture"  name="PlafMont" :value="PlafMont" @change.native="PlafMont=$event.target.value" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" type="number" label="Ticket Verre"  name="TickVerr" :value="TickVerr" @change.native="TickVerr=$event.target.value" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" type="number" label="Plafon verre"  name="PlafVerr" :value="PlafVerr" @change.native="PlafVerr=$event.target.value" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" type="number" label="Ticket Forfetaire"  name="TickForf" :value="TickForf" @change.native="TickForf=$event.target.value" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" type="number" label="Plafond Forfetaire"  name="PlafForf" :value="PlafForf" @change.native="PlafForf=$event.target.value" />
              </div>

            </div>
            <div class="vx-row">
              <div class="vx-col w-full">
                <div class="mt-8 flex flex-wrap items-center justify-end">
                  <vs-button class="ml-auto mt-2" @click="AddPriseEnCharge">Enregistrer</vs-button>
                  <vs-button class="ml-4 mt-2" type="border" color="warning" @click="reset_data_priseEncharge">Annuler</vs-button>
                </div>
              </div>
            </div>

          </vx-card>
        </div>
      </div>
    </vs-popup>
    <vs-popup fullscreen title="Client" :active.sync="popupActiveClient">
      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full mt-5">
          <vs-select v-model="Civilite" class="w-full select-large mt-4" label="Civilité">
            <vs-select-item :key="index" :value="item.text" :text="item.value" v-for="(item,index) in civilites" class="w-full" />
          </vs-select>
        </div>
        <div class="vx-col md:w-1/3 w-full mt-5">
            <vs-input label="Nom" :value="Nom" @change.native="Nom=$event.target.value" class="w-full mt-4" />
        </div>
        <div class="vx-col md:w-1/3 w-full mt-5">
            <vs-input label="Prenoms" :value="Prenoms" @change.native="Prenoms=$event.target.value" class="w-full mt-4" />
        </div>
        <div class="vx-col md:w-1/3 w-full mt-5">
            <vs-input label="Alias" :value="Alias" @change.native="Alias=$event.target.value" class="w-full mt-4" />
        </div>

        <div class="vx-col md:w-1/3 w-full mt-5">
            <vs-input label="Profession" :value="Profession" @change.native="Profession=$event.target.value" class="w-full mt-4" />
        </div>
        <div class="vx-col md:w-1/3 w-full mt-5">
          <div class="mt-4">
            <label class="text-sm">Anniversaire</label>
            <datepicker :language="languages[language]" format="dd MMMM yyyy" v-model="DateNaiss" class="w-full" ></datepicker>
          </div>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full mt-4">
          <vs-input label="Adresse" :value="adresse_client" @change.native="adresse_client=$event.target.value" class="w-full" />
        </div>
        <div class="vx-col md:w-1/3 w-full mt-4">
            <vs-input type="email" label="Email" :success="email ? true : false" success-text="Mail valide" :value="email" @change.native="email=$event.target.value" class="w-full" />
        </div>
        <div class="vx-col md:w-1/3 w-full mt-4">
          <vs-select autocomplete label="Pays" class="w-full" v-model="pays_client" >
            <vs-select-item :key="index" :value="item.pays" :text="item.pays" v-for="(item, index) in     PaysData" />
          </vs-select>
        </div>
        <div class="vx-col md:w-1/3 w-full mt-4">
          <vs-select autocomplete label="Ville" class="w-full" v-model="ville" >
            <vs-select-item v-for="(item, index) in villeData" :key="index" :value="item.libelleVille" :text="item.libelleVille"/>
          </vs-select>
        </div>
        <div class="vx-col md:w-1/3 w-full mt-4">
          <vs-select autocomplete label="Quartier" class="w-full" v-model="quartier" >
            <vs-select-item v-for="(item, index) in quartierData" :key="index" :value="item.libelleQtier" :text="item.libelleQtier"/>
          </vs-select>
        </div>
        <div class="vx-col md:w-1/3 w-full mt-4">
          <vs-input label="Localisation" :value="localite" @change.native="localite=$event.target.value" class="w-full" />
        </div>
        <div class="vx-col md:w-1/3 w-full mt-4">
          <vs-input label="Fixe" :value="tel" @change.native="tel=$event.target.value" class="w-full" />
        </div>
        <div class="vx-col md:w-1/3 w-full mt-4">
          <vs-input label="Mobile" :value="mobile" @change.native="mobile=$event.target.value" class="w-full" />
        </div>
        <div class="vx-col md:w-1/3 w-full mt-4">
          <vs-select autocomplete label="Langue" class="w-full" v-model="Langue">
          <vs-select-item :key="index" :value="item.code" :text="item.langue" v-for="(item, index) in Languages" />
        </vs-select>
        </div>
        <div class="vx-col md:w-1/3 w-full mt-4">
          <div class="vx-row">
            <div class="vx-col">
              <vs-checkbox label="Assure" v-model="Assure" class="w-full mt-1" >Assuré </vs-checkbox>
            </div>
            <div class="vx-col">
              <vs-checkbox label="PasSMS" v-model="PasSMS" class="w-full mt-1" >Pas SMS </vs-checkbox>
            </div>
            <div class="vx-col">
              <vs-checkbox label="PasMail" v-model="PasMail" class="w-full mt-1" >Pas Mail </vs-checkbox>
            </div>
            <div class="vx-col">
              <vs-checkbox label="CarteFidelite" v-model="CarteFidelite" class="w-full mt-1" >Fidelité </vs-checkbox>
            </div>

            <!-- <div class="vx-col">
              <vs-checkbox label="ChefFamille" v-model="ChefFamille" class="w-full mt-1" >Chef de famille</vs-checkbox>
            </div> -->
            <div class="vx-col">
              <vs-checkbox label="DCD" v-model="DCD" class="w-full mt-1" >DCD </vs-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full mt-4">
          <vs-input label="Remarque" :value="remarque" @change.native="remarque=$event.target.value" class="w-full" />
        </div>
        <div class="vx-col md:w-1/3 w-full mt-4">
          <vs-input label="Activité extra" :value="Activitextra" @change.native="Activitextra=$event.target.value" class="w-full" />
        </div>
      </div>
      <div class="vx-row mt-5">
        <div class="vx-col w-full">
          <vs-button class="mr-3 mb-2" @click="createClient">Enregister</vs-button>
          <vs-button color="warning" type="border" class="mb-2" @click="reset_data_client()">Annuler</vs-button>
        </div>
      </div>
    </vs-popup>
    <div class="vx-row">
      <div class="vx-col md:w-1/3">
        <vx-card class="w-full">
          <h5>Info assurance</h5>
          <div class="m-5 p-5" style="border-left: 1px dashed gray ;">

            <div class="vx-row">
              <div class="vx-col w-full">
                <p>N°Bon: {{RefPEC ? RefPEC : '' }}</p>
                <p>Entreprise: {{entrepise ? entrepise : '' }}</p>
                <p>Assurance: {{assuran ? assuran : '' }}</p>
                <p>Matricule: {{matricule ? matricule : '' }}</p>
                <p>Assuré (e) principal(e): {{assur ? assur : '' }}</p>
                <p>Bénéficiaire: {{patient ? patient : '' }}</p>
                <p>Ticket: {{ticket ? `${ticket}%` : '' }}</p>
                <p>Plafond: {{plafon ? `${moneyFormatter(plafon)} FCFA` : '' }}</p>
                <p>Part assurance: {{calculParClient ? `${moneyFormatter(calculParAssurance)} FCFA` : '' }}</p>
                <p>Part client: {{plafon ? `${moneyFormatter(calculParClient)} FCFA` : '' }}</p>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
      <div class="vx-col md:w-2/3">
        <vx-card class="w-full">
          <div class="vx-row w-full flex flex-col justify-center">
            <div class="vx-col w-full">
              <label class="typo__label" for="client">Client</label>
              <multiselect v-model="client" id="client" label="Nom" :custom-label="fullNameClient" track-by="_id" placeholder="Rechercher un client" open-direction="bottom" :options="clients" :showNoOptions="false" :searchable="true" :loading="isLoading" :internal-search="false" :clear-on-select="false" :close-on-select="false" :options-limit="300" :limit="3" :max-height="600" :show-no-results="false" selectLabel="Appuyez sur Entrée pour sélectionner" selectedLabel="Sélectionné" deselectLabel="Appuyez sur Entrée pour supprimer" @search-change="getClientList" @select="updateClientInfo">
              </multiselect>
            </div>
            <div class="vx-col w-full" v-if="client">
                <p>Civilité: {{client ? client.Civilite : ''}}</p>
                <p>Nom: {{client ? client.Nom : ''}}</p>
                <p>Prenom: {{client ? client.Prenoms : ''}}</p>
                <p>Adresse: {{client ? client.Adresse.mobile ? client.Adresse.mobile : '' : ''}} {{client ? client.Adresse.tel ? `/ ${client.Adresse.tel}` : '' : ''}}</p>
                <p>Email: {{client ? client.Adresse.email ? client.Adresse.email : '' : ''}}</p>
            </div>
          </div>
          <vs-row vs-type="flex" vs-justify="center">
             <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12" v-if="client">
                <vx-tooltip text="Supprimer le client" position="top"> <vs-button type="gradient" color="dark" icon-pack="feather" icon="icon-trash-2" @click="client=null"></vs-button> </vx-tooltip>
             </vs-col>
             <vs-col v-if="!client" class="mt-5"  vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
              <h1>OU</h1>
             </vs-col>
             <vs-col v-if="!client" class="mt-5"  vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
               <vs-button icon-pack="feather" icon="icon-plus" color="primary" type="filled" @click="popupActiveClient=true">Ajouter un nouveau client</vs-button>
             </vs-col>
          </vs-row>
          <div class="vx-row">
            <div class="vx-col w-full">
            </div>
          </div>
          <template v-if="client">
            <br><br><br><br>
          </template>
          <template v-else>
            <br><br><br><br><br><br>
          </template>
        </vx-card>
      </div>
    </div>
    <div class="vx-row mt-5">
      <div class="vx-col w-full">
        <vx-card>
          <div class="vx-row">
            <div class="vx-col md:w-1/4">
              <label for="DateCommd">Date*</label>
              <datepicker v-model="DateCommd" :language="languages[language]" format="d MMMM yyyy" name="DateCommd"></datepicker>
            </div>
            <div class="vx-col md:w-1/4">
              <vs-select label="Prescripteur" autocomplete class="selectExample w-full select-large" v-model="Idprescripteur" @change="goToNewPrescripteur">
                <vs-select-item value="new_prescripteur" text="Nouveau" />
                <vs-divider/>
                <vs-select-item :key="index" :value="item._id" :text="`${item.NomPresc} ${item.PrenomPresc ? item.PrenomPresc : ''}`" v-for="(item,index) in prescripteurs" />
              </vs-select>
            </div>
            <div class="vx-col md:w-1/4">
              <vs-select label="Ordonnances" autocomplete class="selectExample w-full select-large" v-model="RefOrd" @change="OrdonnanceValue">
                <vs-select-item :key="index" :value="item.RefOrd" :text="item.RefOrd" v-for="(item,index) in ordonnances" />
              </vs-select>
            </div>
            <div class="vx-col md:w-1/4">
              <vs-select label="Prise en charge" autocomplete class="selectExample w-full select-large" v-model="RefPEC" @change="PriseEnChargeNouveau">
                <vs-select-item value="new_prise_en_charge" text="Nouveau" />
                <vs-divider/>
                <vs-select-item :key="index" :value="item.RefPEC" :text="item.RefPEC" v-for="(item,index) in priseEnCharges" />
              </vs-select>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
    <div class="vx-row mt-5">
      <div class="vx-col w-full">
        <vx-card>
          <div class="vx-row">
            <div class="vx-col w-full">
              <h2>Prescription</h2>
            </div>
            <vs-divider/>
            <div class="vx-col md:3/6">
              <div class="vx-row">
                <div class="vx-col mt-8">
                  <span class="font-black">OD</span>
                  <br /><br />
                  <feather-icon
                  icon="ArrowDownIcon"
                  class="cursor-pointer"
                  @click="CopyAllPrescriptionODToOG()" />
                </div>
                <div class="vx-col  text-center">
                  <vs-input class="w-full" size="small" type="text" label="SPH"  name="SphVD" :value="SphVD"  @change.native="FormateSPHOD($event.target.value)" />
                  <br>
                  <feather-icon
                  icon="ArrowDownIcon"
                  class="cursor-pointer"
                  @click="CopySphVDToSphVG()" />
                </div>
                <div class="vx-col  text-center">
                  <vs-input class="w-full" size="small" type="text" label="CYL"  name="CylVD" :value="CylVD"  @change.native="FormateCYLOD($event.target.value)" />
                  <br>
                  <feather-icon
                  icon="ArrowDownIcon"
                  class="cursor-pointer"
                  @click="CopyCylVDToCylVG()" />
                </div>
                <div class="vx-col  text-center">
                  <vs-input class="w-full" size="small" type="text" label="AXE"  name="AxeVD" :value="AxeVD" @change.native="AxeVD=$event.target.value" />
                  <br>
                  <feather-icon
                  icon="ArrowDownIcon"
                  class="cursor-pointer"
                  @click="CopyAxeVDToAxeVG()" />
                </div>
                <div class="vx-col text-center">
                  <vs-input class="w-full" size="small" type="text" label="ADDITION"  name="AddVD" :value="AddVD" @change.native="FormateAddVD($event.target.value)" />
                  <br>
                  <feather-icon
                  icon="ArrowDownIcon"
                  class="cursor-pointer"
                  @click="CopyAddVDToAddVG()" />
                </div>
              </div>
              <div class="vx-row">
                <div class="vx-col mt-8">
                  <span class="font-black">OG</span>
                </div>
                <div class="vx-col text-center">
                  <vs-input class="w-full" size="small" type="text" label="SPH"  name="SphVG" :value="SphVG" @change.native="FormateSPHOG($event.target.value)" />
                </div>
                <div class="vx-col text-center">
                  <vs-input class="w-full" size="small" type="text" label="CYL"  name="CylVG" :value="CylVG"  @change.native="FormateCYLOG($event.target.value)" />
                </div>
                <div class="vx-col text-center">
                  <vs-input class="w-full" size="small" type="text" label="AXE"  name="AxeVG" :value="AxeVG" @change.native="AxeVG=$event.target.value" />
                </div>
                <div class="vx-col text-center">
                  <vs-input class="w-full" size="small" type="text" label="ADDITION"  name="AddVG" :value="AddVG" @change.native="FormateAddVG($event.target.value)" />
                </div>
              </div>
            </div>
            <div class="vx-col md:2/6">
              <div class="vx-row">
                <div class="vx-col text-center">
                  <vs-input class="w-full" size="small" type="text" label="ECART VL"  name="EcartVlOD" :value="EcartVlOD" @change.native="EcartVlOD=$event.target.value" />
                  <br>
                  <feather-icon
                  icon="ArrowDownIcon"
                  class="cursor-pointer"
                  @click="CopyEcartVlODToOG()" />
                </div>
                <div class="vx-col text-center">
                  <vs-input class="w-full" size="small" type="text" label="ECART VP"  name="EcartVpOD" :value="EcartVpOD" @change.native="EcartVpOD=$event.target.value" />
                  <br>
                  <feather-icon
                  icon="ArrowDownIcon"
                  class="cursor-pointer"
                  @click="CopyEcartVpODToOG()" />
                </div>
              </div>
              <div class="vx-row">
                <div class="vx-col text-center">
                  <vs-input class="w-full" size="small" type="text" label="ECART VL"  name="EcartVlOG" :value="EcartVlOG" @change.native="EcartVlOG=$event.target.value" />
                </div>
                <div class="vx-col text-center">
                  <vs-input class="w-full" size="small" type="text" label="ECART VP"  name="EcartVPOG" :value="EcartVpOG" @change.native="EcartVpOG=$event.target.value" />
                </div>
              </div>
            </div>

          </div>
        </vx-card>
      </div>
    </div>
    <div class="vx-row mt-5">
      <div class="vx-col w-full">
        <vx-card>
          <div class="vx-row">
            <div class="vx-col w-full">
              <h2>Montures</h2>
            </div>
            <vs-divider/>
            <div class="vx-col w-full">
              <div class="vx-row">
                <div class="vx-col w-full">
                  <h4>N°1</h4>
                </div>
                <div class="vx-col w-full p-10 mt-5" style="background: #FAFAFA">
                  <div class="vx-row">
                    <div class="vx-col md:w-1/5">
                      <vs-select label="Origine monture" autocomplete class="selectExample w-full select-large" v-model.lazy="OrigineMont1"  @change="OrigineMontureFunction(OrigineMont1)">
                        <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in origineMonture" />
                      </vs-select>
                    </div>
                    <div v-if="showMontureStockSelect" class="vx-col md:w-1/5">
                      <label>Monture</label>
                      <multiselect v-model="monture1" id="monture1" label="Etiquette" :custom-label="fullMontureName" track-by="_id" placeholder="Etiquette" open-direction="bottom" :options="montures1" :showNoOptions="false" :searchable="true" :loading="isLoading" :internal-search="false" :clear-on-select="false" :close-on-select="false" :options-limit="300" :limit="3" :max-height="600" :show-no-results="false" selectLabel="Entrée pour sélectionner" selectedLabel="Sélectionné" deselectLabel="Entrée pour supprimer" @search-change="getArticleInstock1" @select="onSuggestSelectArticle1">
                      </multiselect>
                    </div>
                    <div v-if="showMontureClientInput" class="vx-col md:w-1/5">
                      <vs-input class="w-full" type="text" label="Monture"  name="libelleMontureClient" :value="libelleMontureClient1" @change.native="libelleMontureClient1=$event.target.value" />
                    </div>
                    <div class="vx-col md:w-1/5">
                      <vs-input class="w-full" type="number" label="Hauteur Oeil droit"  name="Ht_Mont1_OD" :value="Ht_Mont1_OD" @change.native="Ht_Mont1_OD=$event.target.value" />
                    </div>
                    <div class="vx-col md:w-1/5">
                      <vs-input class="w-full" type="number" label="Hauteur Oeil gauche"  name="Ht_Mont1_OG" :value="Ht_Mont1_OG" @change.native="Ht_Mont1_OG=$event.target.value" />
                    </div>
                    <div class="vx-col md:w-1/5">
                      <vs-input class="w-full" type="number" min="0" label="Prix Monture"  name="PrixMont1"  :value="PrixMont1" @change.native="PrixMont1=$event.target.value"/>
                    </div>
                  </div>

                  <div class="flex">
                    <div class="w-3/5">
                      <div class="flex">
                        <div class="mr-2 mt-8">
                          <span class="font-black">OD</span>
                          <br /><br />
                          <feather-icon
                          icon="ArrowDownIcon"
                          class="cursor-pointer"
                          @click="CopyRefVerD1ToRefVerG1()" />
                        </div>
                        <div class="w-full  text-center">
                          <vs-input class="w-full" type="text" label="Référence verre"  name="RefVerD1" :value="RefVerD1" @change.native="RefVerD1=$event.target.value" />
                          <br>
                          <feather-icon
                          icon="ArrowDownIcon"
                          class="cursor-pointer"
                          @click="CopyRefVerD1ToRefVerG1()" />
                        </div>
                      </div>
                      <div class="flex">
                        <div class="mr-2 mt-8">
                          <span class="font-black">OG</span>
                        </div>
                        <div class="w-full text-center">
                          <vs-input class="w-full" type="text" label="Référence verre"  name="RefVerG1" :value="RefVerG1" @change.native="RefVerG1=$event.target.value" />
                        </div>
                      </div>
                    </div>
                    <div class="ml-2 mr-2">
                      <div class="vx-row">
                        <div class="vx-col w-full text-center pt-24">
                          <feather-icon
                          icon="SearchIcon"
                          class="cursor-pointer"
                          @click="popupCatalogue=true" />
                        </div>
                      </div>
                    </div>
                    <div class="w-2/5">
                      <div class="flex">
                        <div class="w-full text-center">
                          <vs-input class="w-full" type="number" label="Prix"  name="PrixVerD1" :value="PrixVerD1" @change.native="PrixVerD1=$event.target.value"/>
                          <br>
                          <feather-icon
                          icon="ArrowDownIcon"
                          class="cursor-pointer"
                          @click="CopyRefVerD1ToRefVerG1()" />
                        </div>
                      </div>
                      <div class="flex">
                        <div class="w-full text-center">
                          <vs-input class="w-full" type="number" label="Prix"  name="PrixVerG1" :value="PrixVerG1" @change.native="PrixVerG1=$event.target.value" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vx-col w-full mt-5">
                  <h4>N°2</h4>
                </div>
                <div class="vx-col w-full p-10 mt-5" style="background: #FAFAFA">
                  <div class="vx-row">
                    <div class="vx-col md:w-1/5">
                      <vs-select label="Origine monture" autocomplete class="selectExample w-full select-large" v-model.lazy="OrigineMont2" @change="OrigineMontureProposition2(OrigineMont2)">
                        <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in origineMonture" />
                      </vs-select>
                    </div>
                    <div v-if="showMontureStockSelectP2" class="vx-col md:w-1/5">
                      <label>Monture</label>
                      <multiselect v-model="monture2" id="monture2" label="Etiquette" :custom-label="fullMontureName" track-by="_id" placeholder="Etiquette" open-direction="bottom" :options="montures2" :showNoOptions="false" :searchable="true" :loading="isLoading" :internal-search="false" :clear-on-select="false" :close-on-select="false" :options-limit="300" :limit="3" :max-height="600" :show-no-results="false" selectLabel="Entrée pour sélectionner" selectedLabel="Sélectionné" deselectLabel="Entrée pour supprimer" @search-change="getArticleInstock2" @select="onSuggestSelectArticle2">
                      </multiselect>
                    </div>
                    <div v-if="showMontureClientInputP2" class="vx-col md:w-1/5">
                      <vs-input class="w-full" type="text" label="Monture"  name="libelleMontureClient2" :value="libelleMontureClient2" @change.native="libelleMontureClient2=$event.target.value" />
                    </div>
                    <div class="vx-col md:w-1/5">
                      <vs-input class="w-full" type="text" label="Hauteur Oeil droit"  name="Ht_Mont2_OD" :value="Ht_Mont2_OD" @change.native="Ht_Mont2_OD=$event.target.value" />
                    </div>
                    <div class="vx-col md:w-1/5">
                      <vs-input class="w-full" type="text" label="Hauteur Oeil gauche"  name="Ht_Mont2_OG" :value="Ht_Mont2_OG" @change.native="Ht_Mont2_OG=$event.target.value" />
                    </div>
                    <div class="vx-col md:w-1/5">
                      <vs-input class="w-full" type="number" min="0" label="Prix Monture"  name="PrixMont2" :value="PrixMont2" @change.native="PrixMont2=$event.target.value" />
                    </div>
                  </div>

                  <div class="flex">
                    <div class="w-3/5">
                      <div class="flex">
                        <div class="mr-2 mt-8">
                          <span class="font-black">OD</span>
                          <br /><br />
                          <feather-icon
                          icon="ArrowDownIcon"
                          class="cursor-pointer"
                          @click="CopyRefVerD2ToRefVerG2()" />
                        </div>
                        <div class="w-full  text-center">
                          <vs-input class="w-full" type="text" label="Référence verre"  name="RefVerD2" :value="RefVerD2" @change.native="RefVerD2=$event.target.value" />
                          <br>
                          <feather-icon
                          icon="ArrowDownIcon"
                          class="cursor-pointer"
                          @click="CopyRefVerD2ToRefVerG2()" />
                        </div>
                      </div>
                      <div class="flex">
                        <div class="mr-2 mt-8">
                          <span class="font-black">OG</span>
                        </div>
                        <div class="w-full text-center">
                          <vs-input class="w-full" type="text" label="Référence verre"  name="RefVerG2" :value="RefVerG2" @change.native="RefVerG2=$event.target.value" />
                        </div>
                      </div>
                    </div>
                    <div class="ml-2 mr-2">
                      <div class="vx-row">
                        <div class="vx-col w-full text-center pt-24">
                          <feather-icon
                          icon="SearchIcon"
                          class="cursor-pointer"
                          @click="popupCatalogue2=true" />
                        </div>
                      </div>
                    </div>
                    <div class="w-2/5">
                      <div class="flex">
                        <div class="w-full text-center">
                          <vs-input class="w-full" type="number" label="Prix"  name="PrixVerD2" :value="PrixVerD2" @change.native="PrixVerD2=$event.target.value" />
                          <br>
                          <feather-icon
                          icon="ArrowDownIcon"
                          class="cursor-pointer"
                          @click="CopyRefVerD2ToRefVerG2()" />
                        </div>
                      </div>
                      <div class="flex">
                        <div class="w-full text-center">
                          <vs-input class="w-full" type="number" label="Prix"  name="PrixVerG2" :value="PrixVerG2" @change.native="PrixVerG2=$event.target.value" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vx-col w-full mt-5">
                  <h4>N°3</h4>
                </div>
                <div class="vx-col w-full p-10 mt-5" style="background: #FAFAFA">
                  <div class="vx-row">
                    <div class="vx-col md:w-1/5">
                      <vs-select label="Origine monture" autocomplete class="selectExample w-full select-large" v-model.lazy="OrigineMont3" @change="OrigineMontureProposition3(OrigineMont3)">
                        <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in origineMonture" />
                      </vs-select>
                    </div>
                    <div v-if="showMontureStockSelectP3" class="vx-col md:w-1/5">
                      <label>Monture</label>
                      <multiselect v-model="monture3" id="monture3" label="Etiquette" :custom-label="fullMontureName" track-by="_id" placeholder="Etiquette" open-direction="bottom" :options="montures3" :showNoOptions="false" :searchable="true" :loading="isLoading" :internal-search="false" :clear-on-select="false" :close-on-select="false" :options-limit="300" :limit="3" :max-height="600" :show-no-results="false" selectLabel="Entrée pour sélectionner" selectedLabel="Sélectionné" deselectLabel="Entrée pour supprimer" @search-change="getArticleInstock3" @select="onSuggestSelectArticle3">
                      </multiselect>
                    </div>
                    <div v-if="showMontureClientInputP3" class="vx-col md:w-1/5">
                      <vs-input class="w-full" type="text" label="Monture"  name="libelleMontureClient3" :value="libelleMontureClient3" @change.native="libelleMontureClient3=$event.target.value" />
                    </div>
                    <div class="vx-col md:w-1/5">
                      <vs-input class="w-full" type="text" label="Hauteur Oeil droit"  name="Ht_Mont3_OD" :value="Ht_Mont3_OD" @change.native="Ht_Mont3_OD=$event.target.value" />
                    </div>
                    <div class="vx-col md:w-1/5">
                      <vs-input class="w-full" type="text" label="Hauteur Oeil gauche"  name="Ht_Mont3_OG" :value="Ht_Mont3_OG" @change.native="Ht_Mont3_OG=$event.target.value" />
                    </div>
                    <div class="vx-col md:w-1/5">
                      <vs-input class="w-full" type="number" min="0" label="Prix Monture"  name="PrixMont3" :value="PrixMont3" @change.native="PrixMont3=$event.target.value" />
                    </div>
                  </div>

                  <div class="flex">
                    <div class="w-3/5">
                      <div class="flex">
                        <div class="mr-2 mt-8">
                          <span class="font-black">OD</span>
                          <br /><br />
                          <feather-icon
                          icon="ArrowDownIcon"
                          class="cursor-pointer"
                          @click="CopyRefVerD3ToRefVerG3()" />
                        </div>
                        <div class="w-full  text-center">
                          <vs-input class="w-full" type="text" label="Référence verre"  name="RefVerD3" :value="RefVerD3" @change.native="RefVerD3=$event.target.value" />
                          <br>
                          <feather-icon
                          icon="ArrowDownIcon"
                          class="cursor-pointer"
                          @click="CopyRefVerD3ToRefVerG3()" />
                        </div>
                      </div>
                      <div class="flex">
                        <div class="mr-2 mt-8">
                          <span class="font-black">OG</span>
                        </div>
                        <div class="w-full text-center">
                          <vs-input class="w-full" type="text" label="Référence verre"  name="RefVerG3" :value="RefVerG3" @change.native="RefVerG3=$event.target.value" />
                        </div>
                      </div>
                    </div>
                    <div class="ml-2 mr-2">
                      <div class="vx-row">
                        <div class="vx-col w-full text-center pt-24">
                          <feather-icon
                          icon="SearchIcon"
                          class="cursor-pointer"
                          @click="popupCatalogue3=true" />
                        </div>
                      </div>
                    </div>
                    <div class="w-2/5">
                      <div class="flex">
                        <div class="w-full text-center">
                          <vs-input class="w-full" type="number" label="Prix"  name="PrixVerD3" :value="PrixVerD3" @change.native="PrixVerD3=$event.target.value" />
                          <br>
                          <feather-icon
                          icon="ArrowDownIcon"
                          class="cursor-pointer"
                          @click="CopyRefVerD3ToRefVerG3()" />
                        </div>
                      </div>
                      <div class="flex">
                        <div class="w-full text-center">
                          <vs-input class="w-full" type="number" label="Prix"  name="PrixVerG3" :value="PrixVerG3" @change.native="PrixVerG3=$event.target.value" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
      <div class="vx-col w-full mt-5">
        <vx-card :collapseAction="true">
          <div class="vx-row">
            <div class="vx-col w-full">
              <h4>Remise globale</h4>
            </div>
            <vs-divider/>
          </div>
          <vs-row vs-type="flex" vs-justify="center">
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
              <div class="w-full">
                <label for="description">Description</label>
                <vs-textarea name="description" :value="description" @change.native="description=$event.target.value" />
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
              <div class="">
                <label for="numFacture">Remise</label>
                <vs-input type="number" class="w-full" :value="RemiseCom" @change.native="ConvertRemise($event.target.value)" />
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
              <div class="">
                <label>Ne compte pas pour le CA</label>
                <vs-switch v-model="neComptePasPourLeCA" />
              </div>
            </vs-col>
          </vs-row>
        </vx-card>
      </div>
      <div class="vx-col w-full mt-5" style="background: #808080;color:#FAFAFA;position: fixed;bottom:8px;z-index: 1000">
        <vs-row vs-type="flex" vs-justify="center">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <div>
              <div style="border-bottom: 1px dashed #FAFAFA;">
                Total
              </div>
              <h2 style="color:#FAFAFA">{{moneyFormatter(calculNet)}} FCFA HT</h2>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
            <vs-button color="success" type="gradient" icon-pack="feather" icon="icon-check" @click="saveCommande">Valider</vs-button>
          </vs-col>

        </vs-row>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
const flatPickr = () => import('vue-flatpickr-component')
import 'flatpickr/dist/flatpickr.css'
const Datepicker = () => import('vuejs-datepicker')
import * as lang from 'vuejs-datepicker/src/locale'
import draggable from 'vuedraggable'
import Multiselect from 'vue-multiselect'
import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css'
import axios from '@/axios.js'

export default {
  name: 'Add',
  components: {
    vSelect,
    flatPickr,
    Datepicker,
    draggable,
    Multiselect,
    VueSimpleSuggest
  },
  data () {
    return {
      isLoading: false,

      activeUserInfos: null,
      ordonnances: null,
      priseEnCharges: [],
      RefCmmd: null,
      RefOrd: null,
      clients: [],
      client: null,
      IdOfficine: null,
      numFacture: null,
      IdClient: null,
      Idprescripteur: null,
      IdUser: JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo'))._id : '',
      IdPartenaire: null,
      DatePrescrit: null,
      DateCommd: null,
      SphVD: '',
      SphVG: '',
      CylVD: '',
      CylVG: '',
      AxeVD: '',
      AxeVG: '',
      AddVD: '',
      AddVG: '',

      Ht_Mont1_OD: null,
      Ht_Mont1_OG: null,
      Dctr_Mont1: null,
      EcartVlOD: null,
      EcartVpOD: null,
      EcartVlOG: null,
      EcartVpOG: null,
      Ht_Mont2_OD: null,
      Ht_Mont2_OG: null,
      Ht_Mont3_OD: null,
      Ht_Mont3_OG: null,
      Ht_Mont2: null,
      Ht_Mont3: null,
      Dctr_Mont2: null,
      Dctr_Mont3: null,

      montures1: [],
      IdMont1: null,
      monture1: null,
      libelleMontureClient1: '',
      OrigineMont1: '',
      PrixMont1: 0,
      RefVerG1: '',
      PrixVerG1: 0,
      RefVerD1: '',
      PrixVerD1: 0,
      Suplement1: '',

      montures2: [],
      IdMont2: null,
      monture2: null,
      libelleMontureClient2: '',
      OrigineMont2: '',
      PrixMont2: 0,
      RefVerG2: '',
      PrixVerG2: 0,
      RefVerD2: '',
      PrixVerD2: 0,
      Suplement2: '',

      montures3: [],
      IdMont3: null,
      monture3: null,
      libelleMontureClient3: '',
      OrigineMont3: '',
      PrixMont3: 0,
      RefVerG3: '',
      PrixVerG3: 0,
      RefVerD3: '',
      PrixVerD3: 0,
      Suplement3: '',


      Remarque: '',
      TotalFactBrut: 0,
      RemiseCom: 0,
      TotalFactNet: 0,
      PartClient: 0,
      PartAssu: 0,
      PECharge: false,
      CmdeTSalleMontage: false,
      CmdeAuFourVerre: false,
      CmdeMontageFin: false,
      ComdeLivreClient: false,
      IdProforma: null,
      neComptePasPourLeCA: false,
      description: null,

      propositions: [],
      popupActive: false,
      popupActivePescripteur: false,
      popupActivePriseEncharge: false,
      popupActiveClient: false,
      origineMonture: [
        {
          text: 'Stock',
          value: 'stock'
        },
        {
          text: 'Monture Client',
          value: 'monture_client'
        },
        {
          text: 'Autre',
          value: 'autre'
        }
      ],
      showMontureStockSelect: true,
      showMontureClientInput: false,

      showMontureStockSelectP2: true,
      showMontureClientInputP2: false,

      showMontureStockSelectP3: true,
      showMontureClientInputP3: false,

      showCatalogueSelectVerD1: false,
      showCatalogueSelectVerG1: false,
      showCatalogueSelectVerG2: false,
      showCatalogueSelectVerD2: false,
      catalogueVerreG1: '',
      catalogueVerreD1: '',
      prevRoute: null,

      IdCenterHospi:'',
      NomPresc: '',
      PrenomPresc:'',
      Ristourne: false,
      taux:0,
      TelPresc: '',
      Mobile: '',
      FaxPresc: '',
      AdrPresc: '',
      EmailPresc: '',
      RemarqueP:'',

      RaisonSoc: '',
      AdrCli: '',
      MailCli: '',
      TelCli1: '',
      TelCli2: '',
      TelCli3: '',
      MobCli1: '',
      MobCli2: '',
      FaxCli: '',

      montureClearName1: '',
      montureClearName2: '',
      montureClearName3: '',

      active: false,

      /* PDF Config params */
      showlayout: 'false',
      floatlayout: 'true',
      enabledownload: 'false',
      previewmodal: 'true',
      filename: 'Proforma',
      pdfquality: '2',
      manualpagination: 'false',
      pdfformat: 'a4',
      pdforientation: 'landscape',
      pdfcontentwidth: '100%',

      /* Societe */
      NomSociete:'',
      RegistreCom:'',

      /* Assurance */
      Nom_Abrege:'',
      Nom_Complet:'',
      adresse:'',
      pays:'',
      telephone:'',
      mobile:'',
      fax:'',
      Email:'',
      Site:'',
      RegistreComA:'',
      ComptCont:'',
      ComptBanq:'',
      DelaiRegl: null,

      /* Prise en charge */
      RefPEC: '',
      numBon: '',
      IdSociete: null,
      IdAssurance: null,
      RefCommande: '',
      NumPolice: '',
      Assurer: '',
      NumPatient: '',
      Patient: '',
      NumSalarie: '',
      Salarie: '',
      TickMont: '',
      TickVerr: '',
      PlafMont: '',
      PlafVerr: '',
      TickForf: '',
      PlafForf: '',
      QteMont: '',
      QteVerr: '',
      QtePair: '',

      /* info prise en charge */
      entrepise: '',
      assuran: '',
      matricule: '',
      assur: '',
      patient: '',
      ticket: 0,
      ticketM: 0,
      ticketV: 0,
      plafon: 0,
      plafonM: 0,
      plafonV: 0,
      qtePaire: 0,

      /* Montant facture net */
      factureNet: 0,
      /* new clients */
      civilites: [
        {
          text: 'Prof',
          value: 'Prof'
        },
        {
          text: 'Dr',
          value: 'Dr'
        },
        {
          text: 'M',
          value: 'M'
        },
        {
          text: 'Mme',
          value: 'Mme'
        },
        {
          text: 'Mlle',
          value: 'Mlle'
        },
        {
          text: 'Enft',
          value: 'Enft'
        }
      ],
      Civilite: '',
      Nom: '',
      Prenoms: '',
      Alias: '',
      Profession: '',
      DateNaiss: '',
      adresse_client: '',
      email: '',
      pays_client: '',
      ville: '',
      quartier: '',
      localite: '',
      tel: '',
      mobile_client: '',
      fax_client: '',
      cp: '',
      Activitextra: '',
      Langue: '',
      remarque: '',
      CarteFidelite: false,
      Assure: false,
      DCD: false,
      ChefFamille: false,
      PasSMS: false,
      PasMail: false,
      language: 'fr',
      languages: lang,
      Languages: [
        {code: 'af', langue: 'Afrikaans'},
        {code: 'sq', langue: 'Albanian - shqip'},
        {code: 'am', langue: 'Amharic - አማርኛ'},
        {code: 'ar', langue: 'Arabic - العربية'},
        {code: 'an', langue: 'Aragonese - aragonés'},
        {code: 'hy', langue: 'Armenian - հայերեն'},
        {code: 'ast', langue: 'Asturian - asturianu'},
        {code: 'az', langue: 'Azerbaijani - azərbaycan dili'},
        {code: 'eu', langue: 'Basque - euskara'},
        {code: 'be', langue: 'Belarusian - беларуская'},
        {code: 'bn', langue: 'Bengali - বাংলা'},
        {code: 'bs', langue: 'Bosnian - bosanski'},
        {code: 'br', langue: 'Breton - brezhoneg'},
        {code: 'bg', langue: 'Bulgarian - български'},
        {code: 'ca', langue: 'Catalan - català'},
        {code: 'ckb', langue: 'Central Kurdish - کوردی (دەستنوسی عەرەبی)'},
        {code: 'zh', langue: 'Chinese - 中文'},
        {code: 'zh-HK', langue: 'Chinese (Hong Kong) - 中文（香港）'},
        {code: 'zh-CN', langue: 'Chinese (Simplified) - 中文（简体）'},
        {code: 'zh-TW', langue: 'Chinese (Traditional) - 中文（繁體）'},
        {code: 'co', langue: 'Corsican'},
        {code: 'hr', langue: 'Croatian - hrvatski'},
        {code: 'cs', langue: 'Czech - čeština'},
        {code: 'da', langue: 'Danish - dansk'},
        {code: 'nl', langue: 'Dutch - Nederlands'},
        {code: 'en', langue: 'English'},
        {code: 'en-AU', langue: 'English (Australia)'},
        {code: 'en-CA', langue: 'English (Canada)'},
        {code: 'en-IN', langue: 'English (India)'},
        {code: 'en-NZ', langue: 'English (New Zealand)'},
        {code: 'en-ZA', langue: 'English (South Africa)'},
        {code: 'en-GB', langue: 'English (United Kingdom)'},
        {code: 'en-US', langue: 'English (United States)'},
        {code: 'eo', langue: 'Esperanto - esperanto'},
        {code: 'et', langue: 'Estonian - eesti'},
        {code: 'fo', langue: 'Faroese - føroyskt'},
        {code: 'fil', langue: 'Filipino'},
        {code: 'fi', langue: 'Finnish - suomi'},
        {code: 'fr', langue: 'French - français'},
        {code: 'fr-CA', langue: 'French (Canada) - français (Canada)'},
        {code: 'fr-FR', langue: 'French (France) - français (France)'},
        {code: 'fr-CH', langue: 'French (Switzerland) - français (Suisse)'},
        {code: 'gl', langue: 'Galician - galego'},
        {code: 'ka', langue: 'Georgian - ქართული'},
        {code: 'de', langue: 'German - Deutsch'},
        {code: 'de-AT', langue: 'German (Austria) - Deutsch (Österreich)'},
        {code: 'de-DE', langue: 'German (Germany) - Deutsch (Deutschland)'},
        {code: 'de-LI', langue: 'German (Liechtenstein) - Deutsch (Liechtenstein)'},
        {code: 'de-CH', langue: 'German (Switzerland) - Deutsch (Schweiz)'},
        {code: 'el', langue: 'Greek - Ελληνικά'},
        {code: 'gn', langue: 'Guarani'},
        {code: 'gu', langue: 'Gujarati - ગુજરાતી'},
        {code: 'ha', langue: 'Hausa'},
        {code: 'haw', langue: 'Hawaiian - ʻŌlelo Hawaiʻi'},
        {code: 'he', langue: 'Hebrew - עברית'},
        {code: 'hi', langue: 'Hindi - हिन्दी'},
        {code: 'hu', langue: 'Hungarian - magyar'},
        {code: 'is', langue: 'Icelandic - íslenska'},
        {code: 'id', langue: 'Indonesian - Indonesia'},
        {code: 'ia', langue: 'Interlingua'},
        {code: 'ga', langue: 'Irish - Gaeilge'},
        {code: 'it', langue: 'Italian - italiano'},
        {code: 'it-IT', langue: 'Italian (Italy) - italiano (Italia)'},
        {code: 'it-CH', langue: 'Italian (Switzerland) - italiano (Svizzera)'},
        {code: 'ja', langue: 'Japanese - 日本語'},
        {code: 'kn', langue: 'Kannada - ಕನ್ನಡ'},
        {code: 'kk', langue: 'Kazakh - қазақ тілі'},
        {code: 'km', langue: 'Khmer - ខ្មែរ'},
        {code: 'ko', langue: 'Korean - 한국어'},
        {code: 'ku', langue: 'Kurdish - Kurdî'},
        {code: 'ky', langue: 'Kyrgyz - кыргызча'},
        {code: 'lo', langue: 'Lao - ລາວ'},
        {code: 'la', langue: 'Latin'},
        {code: 'lv', langue: 'Latvian - latviešu'},
        {code: 'ln', langue: 'Lingala - lingála'},
        {code: 'lt', langue: 'Lithuanian - lietuvių'},
        {code: 'mk', langue: 'Macedonian - македонски'},
        {code: 'ms', langue: 'Malay - Bahasa Melayu'},
        {code: 'ml', langue: 'Malayalam - മലയാളം'},
        {code: 'mt', langue: 'Maltese - Malti'},
        {code: 'mr', langue: 'Marathi - मराठी'},
        {code: 'mn', langue: 'Mongolian - монгол'},
        {code: 'ne', langue: 'Nepali - नेपाली'},
        {code: 'no', langue: 'Norwegian - norsk'},
        {code: 'nb', langue: 'Norwegian Bokmål - norsk bokmål'},
        {code: 'nn', langue: 'Norwegian Nynorsk - nynorsk'},
        {code: 'oc', langue: 'Occitan'},
        {code: 'or', langue: 'Oriya - ଓଡ଼ିଆ'},
        {code: 'om', langue: 'Oromo - Oromoo'},
        {code: 'ps', langue: 'Pashto - پښتو'},
        {code: 'fa', langue: 'Persian - فارسی'},
        {code: 'pl', langue: 'Polish - polski'},
        {code: 'pt', langue: 'Portuguese - português'},
        {code: 'pt-BR', langue: 'Portuguese (Brazil) - português (Brasil)'},
        {code: 'pt-PT', langue: 'Portuguese (Portugal) - português (Portugal)'},
        {code: 'pa', langue: 'Punjabi - ਪੰਜਾਬੀ'},
        {code: 'qu', langue: 'Quechua'},
        {code: 'ro', langue: 'Romanian - română'},
        {code: 'mo', langue: 'Romanian (Moldova) - română (Moldova)'},
        {code: 'rm', langue: 'Romansh - rumantsch'},
        {code: 'ru', langue: 'Russian - русский'},
        {code: 'gd', langue: 'Scottish Gaelic'},
        {code: 'sr', langue: 'Serbian - српски'},
        {code: 'sh', langue: 'Serbo-Croatian - Srpskohrvatski'},
        {code: 'sn', langue: 'Shona - chiShona'},
        {code: 'sd', langue: 'Sindhi'},
        {code: 'si', langue: 'Sinhala - සිංහල'},
        {code: 'sk', langue: 'Slovak - slovenčina'},
        {code: 'sl', langue: 'Slovenian - slovenščina'},
        {code: 'so', langue: 'Somali - Soomaali'},
        {code: 'st', langue: 'Southern Sotho'},
        {code: 'es', langue: 'Spanish - español'},
        {code: 'es-AR', langue: 'Spanish (Argentina) - español (Argentina)'},
        {code: 'es-419', langue: 'Spanish (Latin America) - español (Latinoamérica)'},
        {code: 'es-MX', langue: 'Spanish (Mexico) - español (México)'},
        {code: 'es-ES', langue: 'Spanish (Spain) - español (España)'},
        {code: 'es-US', langue: 'Spanish (United States) - español (Estados Unidos)'},
        {code: 'su', langue: 'Sundanese'},
        {code: 'sw', langue: 'Swahili - Kiswahili'},
        {code: 'sv', langue: 'Swedish - svenska'},
        {code: 'tg', langue: 'Tajik - тоҷикӣ'},
        {code: 'ta', langue: 'Tamil - தமிழ்'},
        {code: 'tt', langue: 'Tatar'},
        {code: 'te', langue: 'Telugu - తెలుగు'},
        {code: 'th', langue: 'Thai - ไทย'},
        {code: 'ti', langue: 'Tigrinya - ትግርኛ'},
        {code: 'to', langue: 'Tongan - lea fakatonga'},
        {code: 'tr', langue: 'Turkish - Türkçe'},
        {code: 'tk', langue: 'Turkmen'},
        {code: 'tw', langue: 'Twi'},
        {code: 'uk', langue: 'Ukrainian - українська'},
        {code: 'ur', langue: 'Urdu - اردو'},
        {code: 'ug', langue: 'Uyghur'},
        {code: 'uz', langue: 'Uzbek - o‘zbek'},
        {code: 'vi', langue: 'Vietnamese - Tiếng Việt'},
        {code: 'wa', langue: 'Walloon - wa'},
        {code: 'cy', langue: 'Welsh - Cymraeg'},
        {code: 'fy', langue: 'Western Frisian'},
        {code: 'xh', langue: 'Xhosa'},
        {code: 'yi', langue: 'Yiddish'},
        {code: 'yo', langue: 'Yoruba - Èdè Yorùbá'},
        {code: 'zu', langue: 'Zulu - isiZulu'}
      ],

      /* catalogue verre */
      dataModelVerres: [],
      dataModelVerresOG1: [],
      dataMatricePrixOD1: [],
      dataMatricePrixOG1: [],

      dataModelVerresOD2: [],
      dataModelVerresOG2: [],
      dataMatricePrixOD2: [],
      dataMatricePrixOG2: [],

      dataModelVerresOD3: [],
      dataModelVerresOG3: [],
      dataMatricePrixOD3: [],
      dataMatricePrixOG3: [],

      btnODColor: 'primary',
      btnOGColor: 'lightgray',
      showODCommandeVerre: true,
      showOGCommandeVerre: false,

      showODCommandeVerre2: true,
      showOGCommandeVerre2: false,

      showODCommandeVerre3: true,
      showOGCommandeVerre3: false,

      oldCataloguePriceData: [],
      CataloguePrixData: [],
      cataloguePrixDataFilter: [],

      popupCatalogue: false,
      popupCatalogue2: false,
      popupCatalogue3: false,

      Id_FoyerOD1: null,
      IdGammeVerrOD1: null,
      Id_OptionVerreOD1: null,
      Prix_OptionVerreOD1: 0,
      Id_MatiereOD1: null,
      Id_IndiceOD1: null,
      IdFourVerrOD1: null,
      Id_FabricationOD1: null,
      Prix_FabricationOD1: 0,
      Id_ColorationOD1: null,
      Prix_ColorationOD1: 0,
      Id_TraitementOD1: null,
      Prix_TraitementOD1: 0,

      Id_FoyerOG1: null,
      IdGammeVerrOG1: null,
      Id_OptionVerreOG1: null,
      Prix_OptionVerreOG1: 0,
      Id_MatiereOG1: null,
      Id_IndiceOG1: null,
      IdFourVerrOG1: null,
      Id_FabricationOG1: null,
      Prix_FabricationOG1: 0,
      Id_ColorationOG1: null,
      Prix_ColorationOG1: 0,
      Id_TraitementOG1: null,
      Prix_TraitementOG1: 0,

      Id_FoyerOD2: null,
      IdGammeVerrOD2: null,
      Id_OptionVerreOD2: null,
      Prix_OptionVerreOD2: 0,
      Id_MatiereOD2: null,
      Id_IndiceOD2: null,
      IdFourVerrOD2: null,
      Id_FabricationOD2: null,
      Prix_FabricationOD2: 0,
      Id_ColorationOD2: null,
      Prix_ColorationOD2: 0,
      Id_TraitementOD2: null,
      Prix_TraitementOD2: 0,

      Id_FoyerOG2: null,
      IdGammeVerrOG2: null,
      Id_OptionVerreOG2: null,
      Prix_OptionVerreOG2: 0,
      Id_MatiereOG2: null,
      Id_IndiceOG2: null,
      IdFourVerrOG2: null,
      Id_FabricationOG2: null,
      Prix_FabricationOG2: 0,
      Id_ColorationOG2: null,
      Prix_ColorationOG2: 0,
      Id_TraitementOG2: null,
      Prix_TraitementOG2: 0,

      Id_FoyerOD3: null,
      IdGammeVerrOD3: null,
      Id_OptionVerreOD3: null,
      Prix_OptionVerreOD3: 0,
      Id_MatiereOD3: null,
      Id_IndiceOD3: null,
      IdFourVerrOD3: null,
      Id_FabricationOD3: null,
      Prix_FabricationOD3: 0,
      Id_ColorationOD3: null,
      Prix_ColorationOD3: 0,
      Id_TraitementOD3: null,
      Prix_TraitementOD3: 0,

      Id_FoyerOG3: null,
      IdGammeVerrOG3: null,
      Id_OptionVerreOG3: null,
      Prix_OptionVerreOG3: 0,
      Id_MatiereOG3: null,
      Id_IndiceOG3: null,
      IdFourVerrOG3: null,
      Id_FabricationOG3: null,
      Prix_FabricationOG3: 0,
      Id_ColorationOG3: null,
      Prix_ColorationOG3: 0,
      Id_TraitementOG3: null,
      Prix_TraitementOG3: 0
    }
  },
  computed: {
    typeVerreData () {
      return this.$store.state.verre.types_verre
    },
    matiereVerreData () {
      return this.$store.state.verre.matieres_verres
    },
    indiceVerreData () {
      return this.$store.state.verre.indices
    },
    fournisseur_verres_data () {
      return this.$store.state.verre.fournisseurs_verres
    },
    gammeVerresData () {
      return this.$store.getters['verre/gammeverres']
    },
    versionVerreData () {
      return this.$store.getters['verre/options']
    },
    traitementsData () {
      return this.$store.getters['verre/traitements']
    },
    fabricationData () {
      return this.$store.getters['verre/fabrications']
    },
    colorationData () {
      return this.$store.getters['verre/colorations']
    },
    clientsData () {
      return this.$store.state.client.clients
    },
    prescripteurs () {
      return this.$store.state.prescripteur.prescripteurs
    },
    centreHospitaliers () {
      return this.$store.state.centre_hospitalier.centre_hospitaliers
    },
    Categorie_articleData () {
      return this.$store.state.categorie_article.categorie_articles
    },
    getCategorieMontureId () {
      const categorie = this.$store.state.categorie_article.categorie_articles.find((c) => c.Libelle_Categ === 'Montures')
      return categorie ? categorie._id : null
    },
    totalVerreProposition1 () {
      return (+this.PrixVerG1 + +this.PrixVerD1)
    },
    totalVerreProposition2 () {
      return (+this.PrixVerG2 + +this.PrixVerD2)
    },
    totalVerreProposition3 () {
      return (+this.PrixVerG3 + +this.PrixVerD3)
    },
    villeData () {
      return this.$store.state.pays.villes
    },
    PaysData () {
      return this.$store.state.pays.pays
    },
    quartierData () {
      return this.$store.state.pays.quartiers
    },
    Societes () {
      return this.$store.state.societe.societes
    },
    assuranceData () {
      return this.$store.state.assurance.assurances
    },
    totalSansRemise () {
      return  ((+this.PrixMont1 + +this.PrixVerG1 + +this.PrixVerD1) +  (+this.PrixMont2 + +this.PrixVerG2 + +this.PrixVerD2) + (+this.PrixMont3 + +this.PrixVerG3 + +this.PrixVerD3))
    },
    calculNet () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.factureNet = (((+this.PrixMont1 + +this.PrixVerG1 + +this.PrixVerD1) +  (+this.PrixMont2 + +this.PrixVerG2 + +this.PrixVerD2) +  (+this.PrixMont3 + +this.PrixVerG3 + +this.PrixVerD3)) - +this.RemiseCom).toFixed(0)
      return this.factureNet
    },
    calculParClient () {

      const totalMonture = (+this.PrixMont1 +  +this.PrixMont2 +  +this.PrixMont3).toFixed(0)
      const totalVerre = (+this.PrixVerG1 + +this.PrixVerD1 + +this.PrixVerG2 + +this.PrixVerD2 + +this.PrixVerG3 + +this.PrixVerD3).toFixed(0)

      if ((this.plafonM > 0 || this.ticketM > 0) && this.plafonV === 0 && this.ticketV === 0 && this.plafon === 0 && this.ticket === 0) {
        if (totalMonture >= this.plafonM) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.PartClient = (+this.factureNet - +this.plafonM)
        } else if (this.ticketM > 0) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.PartClient = ((totalMonture * this.ticketM).toFixed(0) / 100) + +totalVerre
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.PartClient = ((+this.plafonM - totalMonture)).toFixed(0) + +totalVerre
        }
      } else if ((this.plafonV > 0 || this.ticketV > 0) && this.plafonM === 0 && this.ticketM === 0 && this.plafon === 0 && this.ticket === 0) {
        if (totalVerre >= this.plafonV) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.PartClient = (+this.factureNet - +this.plafonV)
        } else if (this.ticketV > 0) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.PartClient = ((totalVerre * this.ticketV) / 100).toFixed(0) + +totalMonture
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.PartClient = ((+this.plafonV - totalVerre)).toFixed(0) + +totalMonture
        }
      } else if ((this.plafonV > 0 || this.ticketV > 0) && (this.plafonM > 0 || this.ticketM > 0) && this.plafon === 0 && this.ticket === 0) {
        if (totalVerre >= this.plafonV && totalMonture >= this.plafonM) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.PartClient = (+this.factureNet - (+this.plafonV + +this.plafonM)).toFixed(0)

        } else if (totalVerre >= this.plafonV && totalMonture < this.plafonM) {
          const montantTicketM = ((totalMonture * this.ticketM).toFixed(0) / 100)
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.PartClient = (+this.factureNet - (+this.plafonV + +montantTicketM)).toFixed(0)

        } else if (totalVerre < this.plafonV && totalMonture >= this.plafonM) {
          const montantTicketV = ((totalVerre * this.ticketV).toFixed(0) / 100)
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.PartClient = (+this.factureNet - (+this.plafonM + +montantTicketV)).toFixed(0)

        } else if (this.ticketV > 0 && this.ticketM > 0) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.PartClient = ((totalVerre * this.ticketV) / 100).toFixed(0) + ((totalMonture * this.ticketM).toFixed(0) / 100)
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.PartClient = ((+this.plafonV - totalVerre)).toFixed(0) + ((+this.plafonM - totalMonture)).toFixed(0)
        }
      } else if ((this.plafonV > 0 || this.ticketV > 0) && (this.plafonM > 0 || this.ticketM > 0) && (this.plafon > 0 || this.ticket > 0)) {
        if (this.factureNet >= this.plafon) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.PartClient = (+this.factureNet - +this.plafon).toFixed(0)

        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.PartClient = ((+this.factureNet * this.ticket) / 100).toFixed(0)
          /* si ticker moderateur monture ou verre exist */
        }
      } else if ((this.plafonV === 0 && this.ticketV === 0) && (this.plafonM === 0 && this.ticketM === 0) && (this.plafon > 0 || this.ticket > 0)) {
        if (this.factureNet >= this.plafon) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.PartClient = (+this.factureNet - +this.plafon).toFixed(0)

        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.PartClient = ((+this.factureNet * this.ticket).toFixed(0) / 100)
          /* si ticker moderateur monture ou verre exist */
        }
      } else if (this.factureNet >= this.plafon) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.PartClient = (+this.factureNet - +this.plafon).toFixed(0)

      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.PartClient = ((+this.factureNet * this.ticket).toFixed(0) / 100)
        /* si ticker moderateur monture ou verre exist */
      }

      return this.PartClient
    },
    calculParAssurance () {

      if (this.factureNet >= this.plafon) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.PartAssu = this.plafon
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.PartAssu = (+this.factureNet - +this.PartClient).toFixed(0)
      }
      return this.PartAssu
    },
    matrice_prix_data () {
      return this.$store.state.verre.matrice_prix

    }
  },
  methods: {
    getClientList (inputValue) {
      this.isLoading = true
      return new Promise((resolve, reject) => {
        axios.get(`/api/client-full-search/${this.IdOfficine}/${inputValue}`)
          .then((response) => {
            this.clients = response.data
            this.isLoading = false
            /* resolve(response) */
          })
          .catch((err) => {
            reject(err)
            this.isLoading = false
          })

      })
    },
    fullNameClient ({ Nom, Prenoms }) {
      return `${Nom} ${Prenoms}`
    },
    updateClientInfo (client) {
      this.ordonnances = client.ordonnances
      this.priseEnCharges = client.priseEnCharges
      this.IdClient = client._id
    },
    fullMontureName ({ Etiquette, IdMarque}) {
      return `${Etiquette ? Etiquette : ''} | ${this.getMarqueName(IdMarque)}`
    },
    getArticleInstock1 (inputValue) {
      this.isLoading = true
      return new Promise((resolve, reject) => {
        axios.get(`/api/stock/article-etiquette/${inputValue}/${this.IdOfficine}`)
          .then((response) => {
            this.montures1 = response.data
            this.isLoading = false
            resolve(response)
          })
          .catch((err) => {
            this.isLoading = false
            reject(err)
          })
      })
    },
    onSuggestSelectArticle1 (monture) {
      this.PrixMont1 = monture.PrixVenteTTC
      this.IdMont1 = monture._id
      this.montureClearName1 = `${monture.Etiquette} | ${this.getMarqueName(monture.IdMarque)} ${monture.Modele} ${monture.Couleur}`
    },
    getArticleInstock2 (inputValue) {
      this.isLoading = true
      return new Promise((resolve, reject) => {
        axios.get(`/api/stock/article-etiquette/${inputValue}/${this.IdOfficine}`)
          .then((response) => {
            this.montures2 = response.data
            this.isLoading = false
            resolve(response)
          })
          .catch((err) => {
            this.isLoading = false
            reject(err)
          })
      })
    },
    onSuggestSelectArticle2 (monture) {
      this.PrixMont2 = monture.PrixVenteTTC
      this.IdMont2 = monture._id
      this.montureClearName2 = `${monture.Etiquette} | ${this.getMarqueName(monture.IdMarque)} ${monture.Modele} ${monture.Couleur}`
    },
    getArticleInstock3 (inputValue) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/stock/article-etiquette/${inputValue}/${this.IdOfficine}`)
          .then((response) => {
            this.montures3 = response.data
            this.isLoading = false
            resolve(response)
          })
          .catch((err) => {
            this.isLoading = false
            reject(err)
          })
      })
    },
    onSuggestSelectArticle3 (monture) {
      this.PrixMont3 = monture.PrixVenteTTC
      this.IdMont3 = monture._id
      this.montureClearName3 = `${monture.Etiquette} | ${this.getMarqueName(monture.IdMarque)} ${monture.Modele} ${monture.Couleur}`
    },
    showMatiereName (id) {
      return this.$store.getters['verre/showMatiereName'](id)
    },
    setOptionODPrice1 (id) {
      const option = this.$store.getters['verre/getOptionById'](id)
      const payload = {
        gamme: this.IdGammeVerrOD1,
        indice: this.Id_IndiceOD1
      }
      const base = this.IdGammeVerrOD1 ? this.$store.getters['verre/getOptionByGammeIdAndBase'](payload) : null
      if (base._id === id) {
        this.Prix_OptionVerreOD1 = option ? (option.Prix_vente_version ? option.Prix_vente_version : (option.Prix_achat_version + option.Majore_Prix_Base)) : 0
      } else {
        this.Prix_OptionVerreOD1 = option ? (option.Prix_vente_version ? option.Prix_vente_version : (base.Prix_vente_version + option.Majore_Prix_Base)) : 0
      }
      const showGammeName = this.IdGammeVerrOD1 ? this.$store.getters['verre/showGammeName'](this.IdGammeVerrOD1) : ''
      //console.log('option:', option)
      if (this.IdFourVerrOD1 && this.IdGammeVerrOD1) {
        this.RefVerD1 = `${  showGammeName  } ${option ? option.Libelle_indice_Four : ''  } ${option ? option.Libelle_version : ''}`
      }
    },
    setTraitementODPrice1 (id) {
      const traitement = this.$store.getters['verre/getTraitementById'](id)
      this.Prix_TraitementOD1 = traitement ? traitement.prix_Traitement : 0
    },
    setFabricationODPrice1 (id) {
      const fabrication = this.$store.getters['verre/getFabricationById'](id)
      this.Prix_FabricationOD1 = fabrication ? fabrication.Prix_vente_Fabrication : 0
    },
    setColorationODPrice1 (id) {
      const coloration = this.$store.getters['verre/getColorationById'](id)
      this.Prix_ColorationOD1 = coloration ? coloration.Prix_vente_Coloration : 0
    },
    prixVerreOD1 () {
      this.PrixVerD1 = (+this.Prix_OptionVerreOD1 + +this.Prix_TraitementOD1 + +this.Prix_FabricationOD1 + +this.Prix_ColorationOD1) 
    },
    setOptionOGPrice1 (id) {
      const option = this.$store.getters['verre/getOptionById'](id)
      const payload = {
        gamme: this.IdGammeVerrOG1,
        indice: this.Id_IndiceOG1
      }
      const base = this.IdGammeVerrOG1 ? this.$store.getters['verre/getOptionByGammeIdAndBase'](payload) : null
      if (base._id === id) {
        this.Prix_OptionVerreOG1 = option ? (option.Prix_vente_version ? option.Prix_vente_version : (option.Prix_achat_version + option.Majore_Prix_Base)) : 0
      } else {
        this.Prix_OptionVerreOG1 = option ? (option.Prix_vente_version ? option.Prix_vente_version : (base.Prix_vente_version + option.Majore_Prix_Base)) : 0
      }
      const showGammeName = this.IdGammeVerrOG1 ? this.$store.getters['verre/showGammeName'](this.IdGammeVerrOG1) : ''
      this.RefVerG1 = `${  showGammeName  } ${option ? option.Libelle_indice_Four : ''  } ${option ? option.Libelle_version : ''}`
    },
    setTraitementOGPrice1 (id) {
      const traitement = this.$store.getters['verre/getTraitementById'](id)
      this.Prix_TraitementOG1 = traitement ? traitement.prix_Traitement : 0
    },
    setFabricationOGPrice1 (id) {
      const fabrication = this.$store.getters['verre/getFabricationById'](id)
      this.Prix_FabricationOG1 = fabrication ? fabrication.Prix_vente_Fabrication : 0
    },
    setColorationOGPrice1 (id) {
      const coloration = this.$store.getters['verre/getColorationById'](id)
      this.Prix_ColorationOG1 = coloration ? coloration.Prix_vente_Coloration : 0
    },
    prixVerreOG1 () {
      this.PrixVerG1 = (+this.Prix_OptionVerreOG1 + +this.Prix_TraitementOG1 + +this.Prix_FabricationOG1 + +this.Prix_ColorationOG1)
    },

    setOptionODPrice2 (id) {
      const option = this.$store.getters['verre/getOptionById'](id)
      const payload = {
        gamme: this.IdGammeVerrOD2,
        indice: this.Id_IndiceOD2
      }
      const base = this.IdGammeVerrOD2 ? this.$store.getters['verre/getOptionByGammeIdAndBase'](payload) : null
      if (base._id === id) {
        this.Prix_OptionVerreOD2 = option ? (option.Prix_vente_version ? option.Prix_vente_version : (option.Prix_achat_version + option.Majore_Prix_Base)) : 0
      } else {
        this.Prix_OptionVerreOD2 = option ? (option.Prix_vente_version ? option.Prix_vente_version : (base.Prix_vente_version + option.Majore_Prix_Base)) : 0
      }
      const showGammeName = this.IdGammeVerrOD2 ? this.$store.getters['verre/showGammeName'](this.IdGammeVerrOD2) : ''
      //console.log('option:', option)
      if (this.IdFourVerrOD2 && this.IdGammeVerrOD2) {
        this.RefVerD2 = `${  showGammeName  } ${option ? option.Libelle_indice_Four : ''  } ${option ? option.Libelle_version : ''}`
      }
    },
    setTraitementODPrice2 (id) {
      const traitement = this.$store.getters['verre/getTraitementById'](id)
      this.Prix_TraitementOD2 = traitement ? traitement.prix_Traitement : 0
    },
    setFabricationODPrice2 (id) {
      const fabrication = this.$store.getters['verre/getFabricationById'](id)
      this.Prix_FabricationOD2 = fabrication ? fabrication.Prix_vente_Fabrication : 0
    },
    setColorationODPrice2 (id) {
      const coloration = this.$store.getters['verre/getColorationById'](id)
      this.Prix_ColorationOD2 = coloration ? coloration.Prix_vente_Coloration : 0
    },
    prixVerreOD2 () {
      this.PrixVerD2 = (+this.Prix_OptionVerreOD2 + +this.Prix_TraitementOD2 + +this.Prix_FabricationOD2 + +this.Prix_ColorationOD2)
      return (+this.Prix_OptionVerreOD2 + +this.Prix_TraitementOD2 + +this.Prix_FabricationOD2 + +this.Prix_ColorationOD2) 
    },

    setOptionOGPrice2 (id) {
      const option = this.$store.getters['verre/getOptionById'](id)
      const payload = {
        gamme: this.IdGammeVerrOG2,
        indice: this.Id_IndiceOG2
      }
      const base = this.IdGammeVerrOG2 ? this.$store.getters['verre/getOptionByGammeIdAndBase'](payload) : null
      if (base._id === id) {
        this.Prix_OptionVerreOG2 = option ? (option.Prix_vente_version ? option.Prix_vente_version : (option.Prix_achat_version + option.Majore_Prix_Base)) : 0
      } else {
        this.Prix_OptionVerreOG2 = option ? (option.Prix_vente_version ? option.Prix_vente_version : (base.Prix_vente_version + option.Majore_Prix_Base)) : 0
      }
      const showGammeName = this.IdGammeVerrOG2 ? this.$store.getters['verre/showGammeName'](this.IdGammeVerrOG2) : ''
      this.RefVerG2 = `${  showGammeName  } ${option ? option.Libelle_indice_Four : ''  } ${option ? option.Libelle_version : ''}`
    },
    setTraitementOGPrice2 (id) {
      const traitement = this.$store.getters['verre/getTraitementById'](id)
      this.Prix_TraitementOG2 = traitement ? traitement.prix_Traitement : 0
    },
    setFabricationOGPrice2 (id) {
      const fabrication = this.$store.getters['verre/getFabricationById'](id)
      this.Prix_FabricationOG2 = fabrication ? fabrication.Prix_vente_Fabrication : 0
    },
    setColorationOGPrice2 (id) {
      const coloration = this.$store.getters['verre/getColorationById'](id)
      this.Prix_ColorationOG2 = coloration ? coloration.Prix_vente_Coloration : 0
    },
    prixVerreOG2 () {
      this.PrixVerG2 = (+this.Prix_OptionVerreOG2 + +this.Prix_TraitementOG2 + +this.Prix_FabricationOG2 + +this.Prix_ColorationOG2)
      return (+this.Prix_OptionVerreOG2 + +this.Prix_TraitementOG2 + +this.Prix_FabricationOG2 + +this.Prix_ColorationOG2) 
    },

    setOptionODPrice3 (id) {
      const option = this.$store.getters['verre/getOptionById'](id)
      const payload = {
        gamme: this.IdGammeVerrOD3,
        indice: this.Id_IndiceOD3
      }
      const base = this.IdGammeVerrOD3 ? this.$store.getters['verre/getOptionByGammeIdAndBase'](payload) : null
      if (base._id === id) {
        this.Prix_OptionVerreOD3 = option ? (option.Prix_vente_version ? option.Prix_vente_version : (option.Prix_achat_version + option.Majore_Prix_Base)) : 0
      } else {
        this.Prix_OptionVerreOD3 = option ? (option.Prix_vente_version ? option.Prix_vente_version : (base.Prix_vente_version + option.Majore_Prix_Base)) : 0
      }
      const showGammeName = this.IdGammeVerrOD3 ? this.$store.getters['verre/showGammeName'](this.IdGammeVerrOD3) : ''
      //console.log('option:', option)
      if (this.IdFourVerrOD3 && this.IdGammeVerrOD3) {
        this.RefVerD3 = `${  showGammeName  } ${option ? option.Libelle_indice_Four : ''  } ${option ? option.Libelle_version : ''}`
      }
    },
    setTraitementODPrice3 (id) {
      const traitement = this.$store.getters['verre/getTraitementById'](id)
      this.Prix_TraitementOD3 = traitement ? traitement.prix_Traitement : 0
    },
    setFabricationODPrice3 (id) {
      const fabrication = this.$store.getters['verre/getFabricationById'](id)
      this.Prix_FabricationOD3 = fabrication ? fabrication.Prix_vente_Fabrication : 0
    },
    setColorationODPrice3 (id) {
      const coloration = this.$store.getters['verre/getColorationById'](id)
      this.Prix_ColorationOD3 = coloration ? coloration.Prix_vente_Coloration : 0
    },
    prixVerreOD3 () {
      this.PrixVerD3 = (+this.Prix_OptionVerreOD3 + +this.Prix_TraitementOD3 + +this.Prix_FabricationOD3 + +this.Prix_ColorationOD3)
      return (+this.Prix_OptionVerreOD3 + +this.Prix_TraitementOD3 + +this.Prix_FabricationOD3 + +this.Prix_ColorationOD3) 
    },

    setOptionOGPrice3 (id) {
      const option = this.$store.getters['verre/getOptionById'](id)
      const payload = {
        gamme: this.IdGammeVerrOG3,
        indice: this.Id_IndiceOG3
      }
      const base = this.IdGammeVerrOG3 ? this.$store.getters['verre/getOptionByGammeIdAndBase'](payload) : null
      if (base._id === id) {
        this.Prix_OptionVerreOG3 = option ? (option.Prix_vente_version ? option.Prix_vente_version : (option.Prix_achat_version + option.Majore_Prix_Base)) : 0
      } else {
        this.Prix_OptionVerreOG3 = option ? (option.Prix_vente_version ? option.Prix_vente_version : (base.Prix_vente_version + option.Majore_Prix_Base)) : 0
      }
      const showGammeName = this.IdGammeVerrOG3 ? this.$store.getters['verre/showGammeName'](this.IdGammeVerrOG3) : ''
      this.RefVerG3 = `${  showGammeName  } ${option ? option.Libelle_indice_Four : ''  } ${option ? option.Libelle_version : ''}`
    },
    setTraitementOGPrice3 (id) {
      const traitement = this.$store.getters['verre/getTraitementById'](id)
      this.Prix_TraitementOG3 = traitement ? traitement.prix_Traitement : 0
    },
    setFabricationOGPrice3 (id) {
      const fabrication = this.$store.getters['verre/getFabricationById'](id)
      this.Prix_FabricationOG3 = fabrication ? fabrication.Prix_vente_Fabrication : 0
    },
    setColorationOGPrice3 (id) {
      const coloration = this.$store.getters['verre/getColorationById'](id)
      this.Prix_ColorationOG3 = coloration ? coloration.Prix_vente_Coloration : 0
    },
    prixVerreOG3 () {
      this.PrixVerG3 = (+this.Prix_OptionVerreOG3 + +this.Prix_TraitementOG3 + +this.Prix_FabricationOG3 + +this.Prix_ColorationOG3)
      return (+this.Prix_OptionVerreOG3 + +this.Prix_TraitementOG3 + +this.Prix_FabricationOG3 + +this.Prix_ColorationOG3) 
    },

    filterVerreDataOD1 () {
      const gammefilterObj = {
        IdFourVerr: this.IdFourVerrOD1,
        IdFoyer: this.Id_FoyerOD1,
        filterCount: (this.IdFourVerrOD1 !== undefined && this.Id_FoyerOD1 !== undefined) ? 2 : ((this.IdFourVerrOD1 !== undefined || this.Id_FoyerOD1 !== undefined) ? 1 : 0)
      }

      //console.log('gammefilterObj:', gammefilterObj)

      this.filterGamme(gammefilterObj)

      // Option verre
      const optionFilterObj = {
        IdFour: this.IdFourVerrOD1,
        IdGam: this.IdGammeVerrOD1,
        IdIndice: this.Id_IndiceOD1,
        filterCount: (this.IdFourVerrOD1 !== undefined && this.IdGammeVerrOD1 !== undefined && this.Id_IndiceOD1 !== undefined) ? 3 : (((this.IdFourVerrOD1 !== undefined && this.IdGammeVerrOD1 !== undefined) || (this.IdFourVerrOD1 !== undefined && this.Id_IndiceOD1 !== undefined) || (this.IdGammeVerrOD1 !== undefined && this.Id_IndiceOD1 !== undefined)) ? 2 : (this.IdFourVerrOD1 !== undefined || this.IdGammeVerrOD1 !== undefined || this.Id_IndiceOD1 !== undefined) ? 1 : 0)
      }
      this.filterOptions(optionFilterObj)

      // Traitement
      const optionTraitemetObj = {
        IdFour: this.IdFourVerrOD1,
        filterCount: (this.IdFourVerrOD1 !== undefined) ? 1 : 0
      }
      this.filterTraitement(optionTraitemetObj)

      // Fabrication
      const optionFabricationObj = {
        IdFour: this.IdFourVerrOD1,
        filterCount: (this.IdFourVerrOD1 !== undefined) ? 1 : 0
      }
      this.filterFabrication(optionFabricationObj)
      // Coloration
      const optionColorationObj = {
        IdFour: this.IdFourVerrOD1,
        filterCount: (this.IdFourVerrOD1 !== undefined) ? 1 : 0
      }
      this.filterColoration(optionColorationObj)

    },
    filterVerreDataOG1 () {
      const gammefilterObj = {
        IdFourVerr: this.IdFourVerrOG1,
        IdFoyer: this.Id_FoyerOG1,
        filterCount: (this.IdFourVerrOG1 !== undefined && this.Id_FoyerOG1 !== undefined) ? 2 : ((this.IdFourVerrOG1 !== undefined || this.Id_FoyerOG1 !== undefined) ? 1 : 0)
      }

      //console.log('gammefilterObj:', gammefilterObj)

      this.filterGamme(gammefilterObj)

      // Option verre
      const optionFilterObj = {
        IdFour: this.IdFourVerrOG1,
        IdGam: this.IdGammeVerrOG1,
        IdIndice: this.Id_IndiceOG1,
        filterCount: (this.IdFourVerrOG1 !== undefined && this.IdGammeVerrOG1 !== undefined && this.Id_IndiceOG1 !== undefined) ? 3 : (((this.IdFourVerrOG1 !== undefined && this.IdGammeVerrOG1 !== undefined) || (this.IdFourVerrOG1 !== undefined && this.Id_IndiceOG1 !== undefined) || (this.IdGammeVerrOG1 !== undefined && this.Id_IndiceOG1 !== undefined)) ? 2 : (this.IdFourVerrOG1 !== undefined || this.IdGammeVerrOG1 !== undefined || this.Id_IndiceOG1 !== undefined) ? 1 : 0)
      }
      this.filterOptions(optionFilterObj)

      // Traitement
      const optionTraitemetObj = {
        IdFour: this.IdFourVerrOG1,
        filterCount: (this.IdFourVerrOG1 !== undefined) ? 1 : 0
      }
      this.filterTraitement(optionTraitemetObj)

      // Fabrication
      const optionFabricationObj = {
        IdFour: this.IdFourVerrOG1,
        filterCount: (this.IdFourVerrOG1 !== undefined) ? 1 : 0
      }
      this.filterFabrication(optionFabricationObj)
      // Coloration
      const optionColorationObj = {
        IdFour: this.IdFourVerrOG1,
        filterCount: (this.IdFourVerrOG1 !== undefined) ? 1 : 0
      }
      this.filterColoration(optionColorationObj)

    },

    filterVerreDataOD2 () {
      const gammefilterObj = {
        IdFourVerr: this.IdFourVerrOD2,
        IdFoyer: this.Id_FoyerOD2,
        filterCount: (this.IdFourVerrOD2 !== undefined && this.Id_FoyerOD2 !== undefined) ? 2 : ((this.IdFourVerrOD2 !== undefined || this.Id_FoyerOD2 !== undefined) ? 1 : 0)
      }

      //console.log('gammefilterObj:', gammefilterObj)

      this.filterGamme(gammefilterObj)

      // Option verre
      const optionFilterObj = {
        IdFour: this.IdFourVerrOD2,
        IdGam: this.IdGammeVerrOD2,
        IdIndice: this.Id_IndiceOD2,
        filterCount: (this.IdFourVerrOD2 !== undefined && this.IdGammeVerrOD2 !== undefined && this.Id_IndiceOD2 !== undefined) ? 3 : (((this.IdFourVerrOD2 !== undefined && this.IdGammeVerrOD2 !== undefined) || (this.IdFourVerrOD2 !== undefined && this.Id_IndiceOD2 !== undefined) || (this.IdGammeVerrOD2 !== undefined && this.Id_IndiceOD2 !== undefined)) ? 2 : (this.IdFourVerrOD2 !== undefined || this.IdGammeVerrOD2 !== undefined || this.Id_IndiceOD2 !== undefined) ? 1 : 0)
      }
      this.filterOptions(optionFilterObj)

      // Traitement
      const optionTraitemetObj = {
        IdFour: this.IdFourVerrOD2,
        filterCount: (this.IdFourVerrOD2 !== undefined) ? 1 : 0
      }
      this.filterTraitement(optionTraitemetObj)

      // Fabrication
      const optionFabricationObj = {
        IdFour: this.IdFourVerrOD2,
        filterCount: (this.IdFourVerrOD2 !== undefined) ? 1 : 0
      }
      this.filterFabrication(optionFabricationObj)
      // Coloration
      const optionColorationObj = {
        IdFour: this.IdFourVerrOD2,
        filterCount: (this.IdFourVerrOD2 !== undefined) ? 1 : 0
      }
      this.filterColoration(optionColorationObj)

    },
    filterVerreDataOG2 () {
      const gammefilterObj = {
        IdFourVerr: this.IdFourVerrOG2,
        IdFoyer: this.Id_FoyerOG2,
        filterCount: (this.IdFourVerrOG2 !== undefined && this.Id_FoyerOG2 !== undefined) ? 2 : ((this.IdFourVerrOG2 !== undefined || this.Id_FoyerOG2 !== undefined) ? 1 : 0)
      }

      //console.log('gammefilterObj:', gammefilterObj)

      this.filterGamme(gammefilterObj)

      // Option verre
      const optionFilterObj = {
        IdFour: this.IdFourVerrOG2,
        IdGam: this.IdGammeVerrOG2,
        IdIndice: this.Id_IndiceOG2,
        filterCount: (this.IdFourVerrOG2 !== undefined && this.IdGammeVerrOG2 !== undefined && this.Id_IndiceOG2 !== undefined) ? 3 : (((this.IdFourVerrOG2 !== undefined && this.IdGammeVerrOG2 !== undefined) || (this.IdFourVerrOG2 !== undefined && this.Id_IndiceOG2 !== undefined) || (this.IdGammeVerrOG2 !== undefined && this.Id_IndiceOG2 !== undefined)) ? 2 : (this.IdFourVerrOG2 !== undefined || this.IdGammeVerrOG2 !== undefined || this.Id_IndiceOG2 !== undefined) ? 1 : 0)
      }
      this.filterOptions(optionFilterObj)

      // Traitement
      const optionTraitemetObj = {
        IdFour: this.IdFourVerrOG2,
        filterCount: (this.IdFourVerrOG2 !== undefined) ? 1 : 0
      }
      this.filterTraitement(optionTraitemetObj)

      // Fabrication
      const optionFabricationObj = {
        IdFour: this.IdFourVerrOG2,
        filterCount: (this.IdFourVerrOG2 !== undefined) ? 1 : 0
      }
      this.filterFabrication(optionFabricationObj)
      // Coloration
      const optionColorationObj = {
        IdFour: this.IdFourVerrOG2,
        filterCount: (this.IdFourVerrOG2 !== undefined) ? 1 : 0
      }
      this.filterColoration(optionColorationObj)

    },

    filterVerreDataOD3 () {
      const gammefilterObj = {
        IdFourVerr: this.IdFourVerrOD3,
        IdFoyer: this.Id_FoyerOD3,
        filterCount: (this.IdFourVerrOD3 !== undefined && this.Id_FoyerOD3 !== undefined) ? 2 : ((this.IdFourVerrOD3 !== undefined || this.Id_FoyerOD3 !== undefined) ? 1 : 0)
      }

      //console.log('gammefilterObj:', gammefilterObj)

      this.filterGamme(gammefilterObj)

      // Option verre
      const optionFilterObj = {
        IdFour: this.IdFourVerrOD3,
        IdGam: this.IdGammeVerrOD3,
        IdIndice: this.Id_IndiceOD3,
        filterCount: (this.IdFourVerrOD3 !== undefined && this.IdGammeVerrOD3 !== undefined && this.Id_IndiceOD3 !== undefined) ? 3 : (((this.IdFourVerrOD3 !== undefined && this.IdGammeVerrOD3 !== undefined) || (this.IdFourVerrOD3 !== undefined && this.Id_IndiceOD3 !== undefined) || (this.IdGammeVerrOD3 !== undefined && this.Id_IndiceOD3 !== undefined)) ? 2 : (this.IdFourVerrOD3 !== undefined || this.IdGammeVerrOD3 !== undefined || this.Id_IndiceOD3 !== undefined) ? 1 : 0)
      }
      this.filterOptions(optionFilterObj)

      // Traitement
      const optionTraitemetObj = {
        IdFour: this.IdFourVerrOD3,
        filterCount: (this.IdFourVerrOD3 !== undefined) ? 1 : 0
      }
      this.filterTraitement(optionTraitemetObj)

      // Fabrication
      const optionFabricationObj = {
        IdFour: this.IdFourVerrOD3,
        filterCount: (this.IdFourVerrOD3 !== undefined) ? 1 : 0
      }
      this.filterFabrication(optionFabricationObj)
      // Coloration
      const optionColorationObj = {
        IdFour: this.IdFourVerrOD3,
        filterCount: (this.IdFourVerrOD3 !== undefined) ? 1 : 0
      }
      this.filterColoration(optionColorationObj)

    },
    filterVerreDataOG3 () {
      const gammefilterObj = {
        IdFourVerr: this.IdFourVerrOG3,
        IdFoyer: this.Id_FoyerOG3,
        filterCount: (this.IdFourVerrOG3 !== undefined && this.Id_FoyerOG3 !== undefined) ? 2 : ((this.IdFourVerrOG3 !== undefined || this.Id_FoyerOG3 !== undefined) ? 1 : 0)
      }

      //console.log('gammefilterObj:', gammefilterObj)

      this.filterGamme(gammefilterObj)

      // Option verre
      const optionFilterObj = {
        IdFour: this.IdFourVerrOG3,
        IdGam: this.IdGammeVerrOG3,
        IdIndice: this.Id_IndiceOG3,
        filterCount: (this.IdFourVerrOG3 !== undefined && this.IdGammeVerrOG3 !== undefined && this.Id_IndiceOG3 !== undefined) ? 3 : (((this.IdFourVerrOG3 !== undefined && this.IdGammeVerrOG3 !== undefined) || (this.IdFourVerrOG3 !== undefined && this.Id_IndiceOG3 !== undefined) || (this.IdGammeVerrOG3 !== undefined && this.Id_IndiceOG3 !== undefined)) ? 2 : (this.IdFourVerrOG3 !== undefined || this.IdGammeVerrOG3 !== undefined || this.Id_IndiceOG3 !== undefined) ? 1 : 0)
      }
      this.filterOptions(optionFilterObj)

      // Traitement
      const optionTraitemetObj = {
        IdFour: this.IdFourVerrOG3,
        filterCount: (this.IdFourVerrOG3 !== undefined) ? 1 : 0
      }
      this.filterTraitement(optionTraitemetObj)

      // Fabrication
      const optionFabricationObj = {
        IdFour: this.IdFourVerrOG3,
        filterCount: (this.IdFourVerrOG3 !== undefined) ? 1 : 0
      }
      this.filterFabrication(optionFabricationObj)
      // Coloration
      const optionColorationObj = {
        IdFour: this.IdFourVerrOG3,
        filterCount: (this.IdFourVerrOG3 !== undefined) ? 1 : 0
      }
      this.filterColoration(optionColorationObj)

    },
    filterOptions (filter) {
      this.$store.dispatch('verre/filterVersionVerre', filter)
    },
    filterGamme (filter) {
      this.$store.dispatch('verre/filterGammeVerre', filter)
    },
    filterTraitement (filter) {
      this.$store.dispatch('verre/filterTraitementVerre', filter)
    },
    filterFabrication (filter) {
      this.$store.dispatch('verre/filterFabricationVerre', filter)
    },
    filterColoration (filter) {
      this.$store.dispatch('verre/filterColorationVerre', filter)
    },

    FormateSPHOD (sph) {
      //convert string to float and fix
      const Sph = parseFloat(sph).toFixed(2)

      this.SphVD = Math.sign(Sph) === 1 || Math.sign(Sph) === 0 ? `+${Sph}` : Sph
    },
    FormateSPHOG (sph) {
      //convert string to float and fix
      const Sph = parseFloat(sph).toFixed(2)

      this.SphVG = Math.sign(Sph) === 1 || Math.sign(Sph) === 0 ? `+${Sph}` : Sph
    },
    FormateCYLOD (cyl) {
      //convert string to float and fix
      const Cyl = parseFloat(cyl).toFixed(2)

      this.CylVD = Math.sign(Cyl) === 1 || Math.sign(Cyl) === 0 ? `+${Cyl}` : Cyl
    },
    FormateCYLOG (cyl) {
      //convert string to float and fix
      const Cyl = parseFloat(cyl).toFixed(2)

      this.CylVG = Math.sign(Cyl) === 1 || Math.sign(Cyl) === 0 ? `+${Cyl}` : Cyl
    },
    FormateAddVD (add) {
      //convert string to float and fix
      const Add = parseFloat(add).toFixed(2)

      this.AddVD = Math.sign(Add) === 1 || Math.sign(Add) === 0 ? `+${Add}` : Add
    },
    FormateAddVG (add) {
      //convert string to float and fix
      const Add = parseFloat(add).toFixed(2)

      this.AddVG = Math.sign(Add) === 1 || Math.sign(Add) === 0 ? `+${Add}` : Add
    },
    FormateEcartVlOD (val) {
      this.EcartVlOD = parseFloat(val).toFixed(1)
    },
    FormateEcartVlOG (val) {
      this.EcartVlOG = parseFloat(val).toFixed(1)
    },
    FormateEcartVpOD (val) {
      this.EcartVpOD = parseFloat(val).toFixed(1)
    },
    FormateEcartVpOG (val) {
      this.EcartVpOG = parseFloat(val).toFixed(1)
    },
    CopyAllPrescriptionODToOG () {
      this.SphVG = this.SphVD
      this.CylVG = this.CylVD
      this.AxeVG = this.AxeVD
      this.AddVG = this.AddVD
      this.EcartVlOG = this.EcartVlOD
      this.EcartVpOG = this.EcartVpOD
    },
    CopySphVDToSphVG () {
      this.SphVG = this.SphVD
    },
    CopyCylVDToCylVG () {
      this.CylVG = this.CylVD
    },
    CopyAxeVDToAxeVG () {
      this.AxeVG = this.AxeVD
    },
    CopyAddVDToAddVG () {
      this.AddVG = this.AddVD
    },
    CopyEcartVlODToOG () {
      this.EcartVlOG = this.EcartVlOD
    },
    CopyEcartVpODToOG () {
      this.EcartVpOG = this.EcartVpOD
    },

    CopyRefVerD1ToRefVerG1 () {
      this.RefVerG1 = this.RefVerD1
      this.PrixVerG1 = this.PrixVerD1
    },
    CopyRefVerD2ToRefVerG2 () {
      this.RefVerG2 = this.RefVerD2
      this.PrixVerG2 = this.PrixVerD2
    },
    CopyRefVerD3ToRefVerG3 () {
      this.RefVerG3 = this.RefVerD3
      this.PrixVerG3 = this.PrixVerD3
    },
    AddProposition1 () {
      const proposition1 = {
        Designation:  this.montureClearName1,
        PrixMont1: this.PrixMont1
      }

      this.propositions.push(proposition1)

      this.$vs.notify({
        title: 'success',
        text: 'success',
        iconPack: 'feather',
        icon: 'icon-check',
        color: 'primary'
      })
    },
    AddProposition2 () {
      const proposition2 = {
        Designation:  this.montureClearName2,
        PrixMont2: this.PrixMont2
      }
      this.propositions.push(proposition2)

      this.$vs.notify({
        title: 'success',
        text: 'success',
        iconPack: 'feather',
        icon: 'icon-check',
        color: 'primary'
      })
    },
    AddProposition3 () {
      const proposition3 = {
        Designation:  this.montureClearName3,
        PrixMont3: this.PrixMont3
      }
      this.propositions.push(proposition3)

      this.$vs.notify({
        title: 'success',
        text: 'success',
        iconPack: 'feather',
        icon: 'icon-check',
        color: 'primary'
      })
    },

    /* conversion nombre en lettre */
    Unite (nombre) {
      let unite = ''
      switch (nombre) {
      case 0:
        unite = 'zéro'
        break
      case 1:
        unite = 'un'
        break
      case 2:
        unite = 'deux'
        break
      case 3:
        unite = 'trois'
        break
      case 4:
        unite = 'quatre'
        break
      case 5:
        unite = 'cinq'
        break
      case 6:
        unite = 'six'
        break
      case 7:
        unite = 'sept'
        break
      case 8:
        unite = 'huit'
        break
      case 9:
        unite = 'neuf'
        break
      }

      return unite
    },
    Dizaine (nombre) {

      let dizaine = ''
      switch (nombre) {
      case 10:
        dizaine = 'dix'
        break
      case 11:
        dizaine = 'onze'
        break
      case 12:
        dizaine = 'douze'
        break
      case 13:
        dizaine = 'treize'
        break
      case 14:
        dizaine = 'quatorze'
        break
      case 15:
        dizaine = 'quinze'
        break
      case 16:
        dizaine = 'seize'
        break
      case 17:
        dizaine = 'dix-sept'
        break
      case 18:
        dizaine = 'dix-huit'
        break
      case 19:
        dizaine = 'dix-neuf'
        break
      case 20:
        dizaine = 'vingt'
        break
      case 30:
        dizaine = 'trente'
        break
      case 40:
        dizaine = 'quarante'
        break
      case 50:
        dizaine = 'cinquante'
        break
      case 60:
        dizaine = 'soixante'
        break
      case 70:
        dizaine = 'soixante-dix'
        break
      case 80:
        dizaine = 'quatre-vingt'
        break
      case 90:
        dizaine = 'quatre-vingt-dix'
        break
      }
      return dizaine
    },
    NumberToLetter (nombre) {
      let quotient = 0, reste = 0
      let numberToLetter = ''
      //__________________________________

      if (nombre.toString().replace(/ /gi, '').length > 15) {
        return 'dépassement de capacité'
      }
      if (isNaN(nombre.toString().replace(/ /gi, ''))) {
        return 'Nombre non valide'
      }

      const nb = parseFloat(nombre.toString().replace(/ /gi, ''))
      if (Math.ceil(nb) !== nb) {
        return  'Nombre avec virgule non géré.'
      }

      const n = nb.toString().length
      switch (n) {
      case 1:
        numberToLetter = this.Unite(nb)
        break
      case 2:
        if (nb > 19) {
          quotient = Math.floor(nb / 10)
          reste = nb % 10
          if (nb < 71 || (nb > 79 && nb < 91)) {
            if (reste === 0) numberToLetter = this.Dizaine(quotient * 10)
            if (reste === 1) numberToLetter = `${this.Dizaine(quotient * 10)  }-et-${  this.Unite(reste)}`
            if (reste > 1) numberToLetter = `${this.Dizaine(quotient * 10)  }-${  this.Unite(reste)}`
          } else numberToLetter = `${this.Dizaine((quotient - 1) * 10)  }-${  this.Dizaine(10 + reste)}`
        } else numberToLetter = this.Dizaine(nb)
        break
      case 3:
        quotient = Math.floor(nb / 100)
        reste = nb % 100
        if (quotient === 1 && reste === 0) numberToLetter = 'cent'
        if (quotient === 1 && reste !== 0) numberToLetter = `cent ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.Unite(quotient)  } cents`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.Unite(quotient)  } cent ${  this.NumberToLetter(reste)}`
        break
      case 4 :
        quotient = Math.floor(nb / 1000)
        reste = nb - (quotient * 1000)
        if (quotient === 1 && reste === 0) numberToLetter = 'mille'
        if (quotient === 1 && reste !== 0) numberToLetter = `mille ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille ${  this.NumberToLetter(reste)}`
        break
      case 5 :
        quotient = Math.floor(nb / 1000)
        reste = nb - (quotient * 1000)
        if (quotient === 1 && reste === 0) numberToLetter = 'mille'
        if (quotient === 1 && reste !== 0) numberToLetter = `mille ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille ${  this.NumberToLetter(reste)}`
        break
      case 6 :
        quotient = Math.floor(nb / 1000)
        reste = nb - (quotient * 1000)
        if (quotient === 1 && reste === 0) numberToLetter = 'mille'
        if (quotient === 1 && reste !== 0) numberToLetter = `mille ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille ${  this.NumberToLetter(reste)}`
        break
      case 7:
        quotient = Math.floor(nb / 1000000)
        reste = nb % 1000000
        if (quotient === 1 && reste === 0) numberToLetter = 'un million'
        if (quotient === 1 && reste !== 0) numberToLetter = `un million ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions ${  this.NumberToLetter(reste)}`
        break
      case 8:
        quotient = Math.floor(nb / 1000000)
        reste = nb % 1000000
        if (quotient === 1 && reste === 0) numberToLetter = 'un million'
        if (quotient === 1 && reste !== 0) numberToLetter = `un million ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions ${  this.NumberToLetter(reste)}`
        break
      case 9:
        quotient = Math.floor(nb / 1000000)
        reste = nb % 1000000
        if (quotient === 1 && reste === 0) numberToLetter = 'un million'
        if (quotient === 1 && reste !== 0) numberToLetter = `un million ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions ${  this.NumberToLetter(reste)}`
        break
      case 10:
        quotient = Math.floor(nb / 1000000000)
        reste = nb - (quotient * 1000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un milliard'
        if (quotient === 1 && reste !== 0) numberToLetter = `un milliard ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards ${  this.NumberToLetter(reste)}`
        break
      case 11:
        quotient = Math.floor(nb / 1000000000)
        reste = nb - (quotient * 1000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un milliard'
        if (quotient === 1 && reste !== 0) numberToLetter = `un milliard ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards ${  this.NumberToLetter(reste)}`
        break
      case 12:
        quotient = Math.floor(nb / 1000000000)
        reste = nb - (quotient * 1000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un milliard'
        if (quotient === 1 && reste !== 0) numberToLetter = `un milliard ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards ${  this.NumberToLetter(reste)}`
        break
      case 13:
        quotient = Math.floor(nb / 1000000000000)
        reste = nb - (quotient * 1000000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un billion'
        if (quotient === 1 && reste !== 0) numberToLetter = `un billion ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions ${  this.NumberToLetter(reste)}`
        break
      case 14:
        quotient = Math.floor(nb / 1000000000000)
        reste = nb - (quotient * 1000000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un billion'
        if (quotient === 1 && reste !== 0) numberToLetter = `un billion ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions ${  this.NumberToLetter(reste)}`
        break
      case 15:
        quotient = Math.floor(nb / 1000000000000)
        reste = nb - (quotient * 1000000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un billion'
        if (quotient === 1 && reste !== 0) numberToLetter = `un billion ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions ${  this.NumberToLetter(reste)}`
        break
      }//fin switch
      /*respect de l'accord de quatre-vingt*/
      if (numberToLetter.substr(numberToLetter.length - 'quatre-vingt'.length, 'quatre-vingt'.length) === 'quatre-vingt') numberToLetter = `${numberToLetter  }s`

      return numberToLetter.toUpperCase()
    },
    /* fin conversion nombre en lettre */
    ConvertRemise (data) {
      if (+data <= 100) {
        const montant = ((this.calculNet * (+data)) / 100).toFixed(0)
        this.RemiseCom = montant
      } else {
        this.RemiseCom = data
      }
    },
    AddPriseEnCharge () {
      const payload = {
        RefPEC: this.numBon,
        IdSociete: this.IdSociete,
        IdAssurance: this.IdAssurance,
        NumPolice: this.NumPolice,
        Assurer: this.Assurer,
        NumPatient: this.NumPatient,
        Patient: this.Patient,
        NumSalarie: this.NumSalarie,
        TickMont: this.TickMont,
        TickVerr: this.TickVerr,
        PlafMont: this.PlafMont,
        PlafVerr: this.PlafVerr,
        TickForf: this.TickForf,
        PlafForf: this.PlafForf,
        id: this.IdClient
      }

      this.$vs.loading({
        type: 'sound'
      })

      this.$store.dispatch('client/addPriseEnCharge', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.getClientById(this.IdClient)
          //this.PriseEnChargeValue()
          this.popupActivePriseEncharge = false
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    reset_data_priseEncharge () {
      this.RefPEC = null
      this.numBon = ''
      this.IdSociete = null
      this.IdAssurance = null
      this.RefCommande = null
      this.NumPolice = null
      this.Assurer = null
      this.NumPatient = null
      this.Patient = null
      this.NumSalarie = null
      this.TickMont = null
      this.TickVerr = null
      this.PlafMont = null
      this.PlafVerr = null
      this.TickForf = null
      this.PlafForf = null
    },
    creatSociete () {
      const payload = {
        NomSociete: this.NomSociete,
        RegistreCom: this.RegistreCom
      }
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('societe/addSociete', payload)
        .then((response) => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.IdSociete = response.data._id
          //this.popupUser = false
          this.reset_data_societe()
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    reset_data_societe () {
      this.NomSociete = ''
      this.RegistreCom = ''
    },

    saveCommande () {
      const payload = {
        RefPEC: this.RefPEC,
        Idprescripteur: this.Idprescripteur,
        IdUser: this.IdUser,
        IdPartenaire: this.IdPartenaire,
        DatePrescrit: this.DatePrescrit,
        DateCommd: this.DateCommd,

        SphVD: this.SphVD,
        SphVG: this.SphVG,
        CylVD: this.CylVD,
        CylVG: this.CylVG,
        AxeVD: this.AxeVD,
        AxeVG: this.AxeVG,
        AddVD: this.AddVD,
        AddVG: this.AddVG,

        EdVD: this.EdVD,
        EdVG: this.EdVG,
        HdVD: this.HdVD,
        HdVG: this.HdVG,

        EcartVlOD: this.EcartVlOD,
        EcartVpOD: this.EcartVpOD,
        EcartVlOG: this.EcartVlOG,
        EcartVpOG: this.EcartVpOG,

        Ht_Mont1_OD: this.Ht_Mont1_OD,
        Ht_Mont1_OG: this.Ht_Mont1_OG,
        Ht_Mont2_OD: this.Ht_Mont2_OD,
        Ht_Mont2_OG: this.Ht_Mont2_OG,
        Ht_Mont3_OD: this.Ht_Mont3_OD,
        Ht_Mont3_OG: this.Ht_Mont3_OG,

        IdMont1: this.IdMont1,
        OrigineMont1: this.OrigineMont1,
        libelleMontureClient: this.libelleMontureClient,
        PrixMont1: this.PrixMont1,
        IdFourVerG1: this.IdFourVerrOG1,
        RefVerG1: this.RefVerG1,
        PrixVerG1: this.PrixVerG1,
        IdFourVerD1: this.IdFourVerrOD1,
        RefVerD1: this.RefVerD1,
        PrixVerD1: this.PrixVerD1,

        IdMont2: this.IdMont2,
        OrigineMont2: this.OrigineMont2,
        libelleMontureClient2: this.libelleMontureClient2,
        PrixMont2: this.PrixMont2,
        IdFourVerG2: this.IdFourVerrOG2,
        RefVerG2: this.RefVerG2,
        PrixVerG2: this.PrixVerG2,
        IdFourVerD2: this.IdFourVerrOD2,
        RefVerD2: this.RefVerD2,
        PrixVerD2: this.PrixVerD2,

        IdMont3: this.IdMont3,
        OrigineMont3: this.OrigineMont3,
        libelleMontureClient3: this.libelleMontureClient3,
        PrixMont3: this.PrixMont3,
        IdFourVerG3: this.IdFourVerrOG3,
        RefVerG3: this.RefVerG3,
        PrixVerG3: this.PrixVerG3,
        IdFourVerD3: this.IdFourVerrOD3,
        RefVerD3: this.RefVerD3,
        PrixVerD3: this.PrixVerD3,

        Remarque: this.Remarque,
        RemiseCom: this.RemiseCom,
        PartClient: this.PartClient,
        PartAssu: this.PartAssu,
        IdProforma: this.IdProforma,

        Id_OptionVerreOD1: this.Id_OptionVerreOD1,
        Prix_OptionVerreOD1: this.Prix_OptionVerreOD1,
        Id_FabricationOD1: this.Id_FabricationOD1,
        Prix_FabricationOD1: this.Prix_FabricationOD1,
        Id_ColorationOD1: this.Id_ColorationOD1,
        Prix_ColorationOD1: this.Prix_ColorationOD1,
        Id_TraitementOD1: this.Id_TraitementOD1,
        Prix_TraitementOD1: this.Prix_TraitementOD1,
        Id_OptionVerreOG1: this.Id_OptionVerreOG1,
        Prix_OptionVerreOG1: this.Prix_OptionVerreOG1,
        Id_FabricationOG1: this.Id_FabricationOG1,
        Prix_FabricationOG1: this.Prix_FabricationOG1,
        Id_ColorationOG1: this.Id_ColorationOG1,
        Prix_ColorationOG1: this.Prix_ColorationOG1,
        Id_TraitementOG1: this.Id_TraitementOG1,
        Prix_TraitementOG1: this.Prix_TraitementOG1,
        Id_OptionVerreOD2: this.Id_OptionVerreOD2,
        Prix_OptionVerreOD2: this.Prix_OptionVerreOD2,
        Id_FabricationOD2: this.Id_FabricationOD2,
        Prix_FabricationOD2: this.Prix_FabricationOD2,
        Id_ColorationOD2: this.Id_ColorationOD2,
        Prix_ColorationOD2: this.Prix_ColorationOD2,
        Id_TraitementOD2: this.Id_TraitementOD2,
        Prix_TraitementOD2: this.Prix_TraitementOD2,
        Id_OptionVerreOG2: this.Id_OptionVerreOG2,
        Prix_OptionVerreOG2: this.Prix_OptionVerreOG2,
        Id_FabricationOG2: this.Id_FabricationOG2,
        Prix_FabricationOG2: this.Prix_FabricationOG2,
        Id_ColorationOG2: this.Id_ColorationOG2,
        Prix_ColorationOG2: this.Prix_ColorationOG2,
        Id_TraitementOG2: this.Id_TraitementOG2,
        Prix_TraitementOG2: this.Prix_TraitementOG2,
        Id_OptionVerreOD3: this.Id_OptionVerreOD3,
        Prix_OptionVerreOD3: this.Prix_OptionVerreOD3,
        Id_FabricationOD3: this.Id_FabricationOD3,
        Prix_FabricationOD3: this.Prix_FabricationOD3,
        Id_ColorationOD3: this.Id_ColorationOD3,
        Prix_ColorationOD3: this.Prix_ColorationOD3,
        Id_TraitementOD3: this.Id_TraitementOD3,
        Prix_TraitementOD3: this.Prix_TraitementOD3,
        Id_OptionVerreOG3: this.Id_OptionVerreOG3,
        Prix_OptionVerreOG3: this.Prix_OptionVerreOG3,
        Id_FabricationOG3: this.Id_FabricationOG3,
        Prix_FabricationOG3: this.Prix_FabricationOG3,
        Id_ColorationOG3: this.Id_ColorationOG3,
        Prix_ColorationOG3: this.Prix_ColorationOG3,
        Id_TraitementOG3: this.Id_TraitementOG3,
        Prix_TraitementOG3: this.Prix_TraitementOG3,

        id: this.IdClient
      }

      this.$vs.loading({
        type: 'sound'
      })

      this.$store.dispatch('client/addCommandeClient', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.$vs.loading.close()
          this.getCommandeClients()
          this.$router.push('/apps/commandeclient/commande-list').catch((err) => { console.log(err) })
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })

    },
    moneyFormatter (montant) {
      /* Formatage du calcul */
      const val = (montant / 1).toFixed(0).replace('.', ',')

      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    getMarqueName (id) {
      const singleMarque = this.$store.getters['marque/getMarqueById'](id)
      return singleMarque ? singleMarque.Libelle_marq : ''
    },
    getArticleMonture (categorie) {
      const montures = this.$store.getters['stock/getArticleByCategorieArticle'](categorie)
      return montures.length ? montures : []
    },
    RefVerD1InfoPut () {
      this.showCatalogueSelectVerD1 = false
    },
    RefVerD2InfoPut () {
      this.showCatalogueSelectVerD2 = false
    },
    RefVerG1InfoPut () {
      this.showCatalogueSelectVerG1 = false
    },
    RefVerG2InfoPut () {
      this.showCatalogueSelectVerG2 = false
    },
    OrdonnanceValue () {

      const ordonnance = this.client.ordonnances.find((o) => o.RefOrd === this.RefOrd)
      this.SphVD = ordonnance.OdVlSph
      this.CylVD = ordonnance.OdVlCyl
      this.AxeVD = ordonnance.OdVlAxe
      this.AddVD = ordonnance.OdAddition
      this.SphVG = ordonnance.OgVlSph
      this.CylVG = ordonnance.OgVlCyl
      this.AxeVG = ordonnance.OgVlAxe
      this.AddVG = ordonnance.OgAddition
    },

    PriseEnChargeValue () {
      const pec = this.client ? this.client.priseEnCharges.find((p) => p.RefPEC === this.RefPEC) : null
      const societe = pec ? this.Societes.find((s) => s._id === pec.IdSociete) : null
      const ass = pec ? this.assuranceData.find((a) => a._id === pec.IdAssurance) : null

      this.entrepise = societe ? societe.NomSociete : null
      this.assuran = ass ? ass.Nom_Abrege : null
      this.matricule = pec ? pec.NumSalarie : null
      this.RefPEC = pec ? pec.RefPEC : ''
      this.assur = pec ? pec.Assurer : null
      this.patient = pec ? pec.Patient : null
      this.ticket = pec ? pec.TickForf : null
      this.ticketM = pec ? pec.TickMont : null
      this.ticketV = pec ? pec.TickVerr : null
      this.plafon = pec ? pec.PlafForf : null
      this.plafonM = pec ? pec.PlafMont : null
      this.plafonV = pec ? pec.PlafVerr : null
      this.qtePaire = pec ? pec.QtePair : null
    },

    OrigineMontureFunction (origine) {
      if (origine === 'stock') {
        this.showMontureStockSelect = true
        this.showMontureClientInput = false

      }
      if (origine === 'monture_client' || origine === 'autre') {
        this.showMontureClientInput = true
        this.showMontureStockSelect = false
      }

    },
    OrigineMontureProposition2 (origine) {
      if (origine === 'stock') {
        this.showMontureStockSelectP2 = true
        this.showMontureClientInputP2 = false
      }
      if (origine === 'monture_client' || origine === 'autre') {
        this.showMontureClientInputP2 = true
        this.showMontureStockSelectP2 = false
      }
    },
    OrigineMontureProposition3 (origine) {
      if (origine === 'stock') {
        this.showMontureStockSelectP3 = true
        this.showMontureClientInputP3 = false
      }
      if (origine === 'monture_client' || origine === 'autre') {
        this.showMontureClientInputP3 = true
        this.showMontureStockSelectP3 = false
      }
    },
    setMonturePrice1 (id) {
      const selectMonture = this.$store.state.stock.articles.find((m) => m._id === id)
      this.PrixMont1 = selectMonture.PrixVenteTTC
      this.montureClearName1 = `${selectMonture.Etiquette} | ${this.getMarqueName(selectMonture.IdMarque)} ${selectMonture.Modele} ${selectMonture.Couleur}`
    },
    setMonturePrice2 (id) {
      const selectMonture = this.$store.state.stock.articles.find((m) => m._id === id)
      this.PrixMont2 = selectMonture.PrixVenteTTC
      this.montureClearName2 = `${selectMonture.Etiquette} | ${this.getMarqueName(selectMonture.IdMarque)} ${selectMonture.Modele} ${selectMonture.Couleur}`
    },
    setMonturePrice3 (id) {
      const selectMonture = this.$store.state.stock.articles.find((m) => m._id === id)
      this.PrixMont3 = selectMonture.PrixVenteTTC
      this.montureClearName3 = `${selectMonture.Etiquette} | ${this.getMarqueName(selectMonture.IdMarque)} ${selectMonture.Modele} ${selectMonture.Couleur}`
    },
    goToNewPrescripteur () {
      if (this.Idprescripteur === 'new_prescripteur') {
        this.popupActivePescripteur = true
        this.Idprescripteur = null
      }
    },
    PriseEnChargeNouveau () {
      if (this.RefPEC === 'new_prise_en_charge') {
        //alert('test')
        this.popupActivePriseEncharge = true
        this.RefPEC = ''
      } else {
        this.PriseEnChargeValue()
      }
    },
    NouveauClient () {
      if (this.IdClient === 'new_client') {
        this.popupActiveClient = true
        this.IdClient = null
      } else {
        this.getClientById(this.IdClient)
      }
    },
    SavePrescripteur () {
      const payload = {
        IdCenterHospi:this.IdCenterHospi,
        NomPresc: this.NomPresc,
        PrenomPresc: this.PrenomPresc,
        taux: this.taux,
        Ristourne: this.Ristourne,
        TelPresc: this.TelPresc,
        Mobile: this.Mobile,
        FaxPresc: this.FaxPresc,
        AdrPresc: this.AdrPresc,
        EmailPresc: this.EmailPresc,
        Remarque: this.RemarqueP
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('prescripteur/addPrescripteur', payload)
        .then((resp) => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.Idprescripteur = resp.data._id
          this.popupActivePescripteur = false
          this.reset_data_prescripteur()
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    reset_data_prescripteur () {
      this.IdCenterHospi = null
      this.NomPresc = null
      this.PrenomPresc = null
      this.RemarqueP = false
      this.TelPresc = null
      this.Mobile = null
      this.FaxPresc = null
      this.AdrPresc = null
      this.EmailPresc = null
    },
    SaveCentreHospitalier () {
      const payload = {
        RaisonSoc: this.RaisonSoc,
        AdrCli: this.AdrCli,
        MailCli: this.MailCli,
        TelCli1: this.TelCli1,
        TelCli2: this.TelCli2,
        TelCli3: this.TelCli3,
        MobCli1: this.MobCli1,
        MobCli2: this.MobCli2,
        FaxCli: this.FaxCli
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('centre_hospitalier/addCentreHospitalier', payload)
        .then((resp) => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.IdCenterHospi = resp.data._id
          this.reset_data_centre()
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    reset_data_centre () {
      this.RaisonSoc = null
      this.AdrCli = null
      this.MailCli = null
      this.TelCli1 = null
      this.TelCli2 = null
      this.TelCli3 = null
      this.MobCli1 = null
      this.MobCli2 = null
      this.FaxCli = null
    },
    createClient () {

      const payload = {
        IdOfficine: this.IdOfficine,
        IdUser: this.IdUser,
        Civilite: this.Civilite,
        Nom: this.Nom,
        Prenoms: this.Prenoms,
        Alias: this.Alias,
        Profession: this.Profession,
        DateNaiss: this.DateNaiss,
        Activitextra: this.Activitextra,
        Langue: this.Langue,
        adresse: this.adresse_client,
        email: this.email,
        pays: this.pays_client,
        ville: this.ville,
        quartier: this.quartier,
        localite: this.localite,
        tel: this.tel,
        mobile: this.mobile,
        /* fax: this.fax_client,
        cp: this.cp, */
        remarque: this.remarque,
        CarteFidelite: this.CarteFidelite,
        Assure: this.Assure,
        DCD: this.DCD,
        /* ChefFamille: this.ChefFamille, */
        PasSMS: this.PasSMS,
        PasMail: this.PasMail

      }
      this.$vs.loading({
        type: 'sound'
      })

      /* setTimeout(() => {
        this.$vs.loading.close()
        this.$router.push('/apps/client/client-list')
      }, 1500) */


      this.$store.dispatch('client/addClient', payload)
        .then((resp) => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.IdClient = resp.data._id
          this.popupActiveClient = false

          this.Civilite = null
          this.Nom = null
          this.Prenoms = null
          this.Alias = null
          this.Profession = null
          this.DateNaiss = null
          this.Activitextra = null
          this.Langue = null
          this.adresse_client = null
          this.remarque = null
          this.CarteFidelite = false
          this.Assure = false
          this.DCD = false
          this.ChefFamille = false
          this.PasSMS = false
          this.PasMail = false
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
      this.IdOfficine = this.activeUserInfos.IdOfficine
      this.IdUser = this.activeUserInfos._id
    },
    getClientsOfficine (id) {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('client/getClientsOfficine', id)
        .then(() => {
          this.$vs.loading.close()
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
        })

    },
    getClientById (id) {
      this.$vs.loading({
        type: 'sound'
      })
      this.$store.dispatch('client/getClientById', id)
        .then((resp) => {
          console.log('client:', resp.data)
          this.client = resp.data
          this.priseEnCharges = resp.data.priseEnCharges
          this.IdClient = this.client._id
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    getPays () {
      this.$store.dispatch('pays/getPays')
        .then(() => { })
        .catch((err) => { console.log(err) })
    },
    getSocietes () {
      this.$store.dispatch('societe/getSocietes')
        .then(() => {
        })
        .catch((err) => { console.log(err) })
    },
    getAssurances () {
      this.$store.dispatch('assurance/getAssurances')
        .catch(err => {
          console.error(err)
        })
    },
    getPrescripteurs () {
      this.$store.dispatch('prescripteur/getPrescripteurs')
        .catch(err => { console.log(err) })
    },
    getCentreHospitalier () {
      this.$store.dispatch('centre_hospitalier/getCentreHospitalier')
        .catch((err) => { console.log(err) })
    },
    getStocks () {
      this.$store.dispatch('stock/getStocks')
        .catch((err) => { console.log(err) })
    },
    getCategorieArticles () {
      this.$store.dispatch('categorie_article/getCategorieArticles')
        .catch(err => {
          console.error(err)
        })
    },
    getMarques () {
      this.$store.dispatch('marque/getMarques')
        .catch(err => {
          console.error(err)
        })
    },
    getCommandeClients () {
      this.$store.dispatch('client/getCommandeClients')
        .catch(err => {
          console.error(err)
        })
    },
    getVilles () {
      this.$store.dispatch('pays/getVilles')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
        })
    },
    getQuartiers () {
      this.$store.dispatch('pays/getQuartiers')
        .catch(err => {
          console.error(err)
        })
    },
    getTypeDeVerres () {
      this.$store.dispatch('verre/getTypeDeVerres')
        .catch((error) => { console.log(error) })
    },
    getMatieresVerre () {
      this.$store.dispatch('verre/getMatieresVerre')
        .catch((error) => { console.log(error) })
    },
    getIndiceVerre () {
      this.$store.dispatch('verre/getIndiceVerre')
        .catch((error) => { console.log(error) })
    },
    getFournisseursVerres () {
      this.$store.dispatch('verre/getFournisseursVerres')
        .catch((error) => { console.log(error) })
    },
    getTraitement () {
      this.$store.dispatch('verre/getTraitement')
        .catch((error) => { console.log(error) })
    },
    getGammeVerres () {
      this.$store.dispatch('verre/getGammeVerres')
        .then(() => {
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getVersionVerre () {
      this.$store.dispatch('verre/getVersionVerre')
        .then(() => {
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getFabrications () {
      this.$store.dispatch('verre/getFabrications')
        .then(() => {
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getColorations () {
      this.$store.dispatch('verre/getColorations')
        .then(() => {
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  created () {
    this.activeUserInfo()
    if (!(this.$store.state.prescripteur.prescripteurs.length > 0)) this.getPrescripteurs()
    if (!(this.$store.state.centre_hospitalier.centre_hospitaliers.length > 0)) this.getCentreHospitalier()
    if (!(this.$store.state.categorie_article.categorie_articles.length > 0)) this.getCategorieArticles()
    if (!(this.$store.state.marque.marques.length > 0)) this.getMarques()
    if (!(this.$store.state.pays.pays.length > 0)) this.getPays()
    if (!(this.$store.state.societe.societes.length > 0)) this.getSocietes()
    if (!(this.$store.state.assurance.assurances.length > 0)) this.getAssurances()

    if (!(this.$store.state.verre.fournisseurs_verres.length > 0)) this.getFournisseursVerres()
    if (!(this.$store.state.verre.types_verre.length > 0)) this.getTypeDeVerres()
    if (!(this.$store.state.verre.matieres_verres.length > 0))  this.getMatieresVerre()
    if (!(this.$store.state.verre.indices.length > 0)) this.getIndiceVerre()
    if (!(this.$store.state.verre.traitements.length > 0)) this.getTraitement()
    if (!(this.$store.state.verre.gammes_verres.length > 0)) this.getGammeVerres()
    if (!(this.$store.state.verre.versions.length > 0)) this.getVersionVerre()
    if (!(this.$store.state.verre.fabrications.length > 0)) this.getFabrications()
    if (!(this.$store.state.verre.colorations.length > 0)) this.getColorations()
  }
}
</script>

<style scoped>
.not-data-table{
  display: none;
}

/* table {
  border:   1px solid black;
} */
.trCustom, .thCustom {
  border: 1px solid black;
  padding: 4px;
}
</style>
